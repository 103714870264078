import { Box, ClickAwayListener, Grow, IconButton, Paper, Popper, Tooltip } from '@mui/material'
import { SvgIcons } from '@utils/icons'
import React, { memo } from 'react'
import { CSVLink } from 'react-csv'
import Button from '../Button/Button'
import Icon from '../Icon'
import { useSelector } from 'react-redux'

// eslint-disable-next-line react/display-name
const TableMenuButton = memo(({ tableData = [], dataToExport, rawData = [], tableColumns, isCustomColumnExport = false, isComplianceTable = false, isMandateTable = false }) => {
	const [anchorEl, setAnchorEl] = React.useState(null)
	const open = Boolean(anchorEl)
	const [exportData, setExportData] = React.useState([])
	const userOrganisation = useSelector((state) => state?.users?.users);


	const escapeCSV = (value) => {
		if (typeof value === 'string' || typeof value === 'number') {
			value = encodeURIComponent(value).replace(/%[0-9A-F]{2}/g, '\u00A0');
		}
		return value;
	};

	const preprocessData = (data) => {
		return data.map(row => {
			return Object.keys(row).reduce((acc, key) => {
				acc[key] = escapeCSV(row[key]);
				return acc;
			}, {});
		});
	};
	
	const handleExportTableData = () => {
		const finalData = tableData.map((each) => {
			const formattedObj = {}
			tableColumns.map((col) => {
				const isActionsColumn = col.id.toLowerCase() === 'raw_data' || col.id.toLowerCase() === 'actions' || col.id === 'ticketData'
				if (!isActionsColumn) {
					const colData =
						typeof col.exportAs === 'function' ? col.exportAs(each.original) : Object.byString(each.original, col.id)
					if (colData || (colData !== undefined && colData != null)) {
						formattedObj[col.id] =
							typeof colData === 'object' || Array.isArray(colData)
								? colData[col.header.toLowerCase()]
									? colData[col.header.toLowerCase()]
									: colData.length > 0
										? colData[0]?.name?.length > 0 ? colData?.map((item) => item.name)?.join(" ,") : colData
										: colData
								: colData
					}
				}
			})

			return formattedObj
		})
		setExportData(preprocessData(finalData))
	}


	//Compliance Pending Policy is a nested data table , general export won't work for such a data. **This is production issue fix***
	const complianceHeadersPendigPolicy = [
		{
			"id": "ref_code",
			"header": "Control Id"
		},
		{
			"id": "category",
			"header": "Control Category"
		},
		{
			"id": "compliance.assigned_to_name",
			"header": "Assigned to"
		},
		{
			"id": "compliance.status",
			"header": "Status"
		},
		{
			"id": "compliance.date",
			"header": "Date"
		},
		{
			"id": "description",
			"header": "Description"
		},
		{
			"id": "compliance.zen_ai.status",
			"header": "ZIN"
		}
	]

	//Mandate Pending Policy is a nested data table , general export won't work for such a data . ** This is a production issue fix ***
	const mandateHeaderPendingPolicy = [
		{
			"id": 'ref_code',
			"header": 'Mandate Id'
		},
		{
			"id": 'mandate_doc.assigned_to_name',
			"header": 'Assigned to'
		},
		{
			"id": 'mandate_doc.status',
			"header": 'Status'
		},
		{
			"id": 'mandate_doc.date',
			"header": 'Date'
		},
		{
			"id": 'description',
			"header": 'Description'
		},
		{
			"id": 'mandate_doc.zen_ai.status',
			"header": 'ZIN'
		}
	]

	const transformHeaders = (headers) => {
		if (!isCustomColumnExport) {
			return headers.map(({ id, header }) => ({
				key: id,
				label: header
			}));
		} else {
			return
		}
	};

	const handleToggle = (event) => {
		setAnchorEl(anchorEl ? null : event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const id = open ? 'simple-popper' : undefined

	React.useEffect(() => {
		if (Array.isArray(dataToExport)) {
			if (isComplianceTable) {
				const exportData = dataToExport.map((item) => {
					const assignedTo = item?.compliance?.assigned_to;
					const assignedUser = userOrganisation?.find((orgItem) => orgItem?.value === assignedTo);
					return {
						...item,
						compliance: {
							...item?.compliance,
							assigned_to_name: assignedUser?.username || 'Not Assigned',
						},
					};
				});
				setExportData(preprocessData(exportData));
			}
			else if (isMandateTable) {
				const exportData = dataToExport.map((item) => {
					const assignedTo = item?.mandate_doc?.assigned_to;
					const assignedUser = userOrganisation?.find((orgItem) => orgItem?.value === assignedTo);
					return {
						...item,
						mandate_doc: {
							...item?.mandate_doc,
							assigned_to_name: assignedUser?.username || 'Not Assigned',
						},
					};
				});
				setExportData(preprocessData(exportData));
			}
			else {
				setExportData(preprocessData(dataToExport));
			}
		} else {
			handleExportTableData();
		}
	}, [JSON.stringify(tableData)]);


	return (
		<div>
			<Tooltip title='Download'>
				<IconButton
					aria-describedby={id}
					sx={{
						marginLeft: '1rem'
					}}
					onClick={handleToggle}
				>
					<Icon ascent='textDark' fontSize={26}>
						<SvgIcons.DOWNLOAD_SVG />
					</Icon>
				</IconButton>
			</Tooltip>
			<Popper
				id={id}
				anchorEl={anchorEl}
				open={open}
				role={undefined}
				transition
				disablePortal
				placement='bottom-end'
				sx={{
					zIndex: 999
				}}
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
						}}
					>
						<div>
							<ClickAwayListener onClickAway={handleClose}>
								<Paper elevation={6} component={Box} borderRadius='0.75rem'>
									<CSVLink target='_blank' data={exportData} headers={isComplianceTable ? transformHeaders(complianceHeadersPendigPolicy) : isMandateTable ? transformHeaders(mandateHeaderPendingPolicy) : transformHeaders(tableColumns)} filename={new Date().toISOString().concat('.csv')}>
										<Button
											// padding=""
											borderRadius='0'
											ascent='trans'
											variant='text'
											display='flex'
											colorAscent={'textDark'}
											alignItems='center'
											sx={{
												display: 'flex',
												width: '100%',
												justifyContent: 'flex-start'
											}}
											textTransform='none'
										>
											<Box display='block' component={'span'} marginRight='0.75rem'>
												<Icon block fontSize={18}>
													<SvgIcons.Export_SVG />
												</Icon>
											</Box>
											Export as .csv
										</Button>
									</CSVLink>
								</Paper>
							</ClickAwayListener>
						</div>
					</Grow>
				)}
			</Popper>
		</div>
	)
})

export default TableMenuButton
