import { DEFAULT_PAGINATION } from '@constants/tableConstants'
import { useLoadingStatus, useUpdateEffect } from '@utils/hooks'
import queryString from 'query-string'
import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AutoRowDetails from './AutoRowDetails'
import Table from './Table'
import { controlSearchReducer, resetCompliancesState } from '@redux/compliances/slice'

const ZeronDataTable = ({
	columns = [],
	initialTableData = [],
	apiFuncService = () => { },
	disableSearchBy,
	apiFuncQueryParams = {},
	disableFilter,
	enableCustomRestructureResponse = false,
	isFullDatatoExport = true,
	handleRestructureResponse = (data) => data,
	hiddenKeysForRowDetails = [],
	renderRowDetailsHeading,
	detailsOnRowClick,
	isCustomRowClick = false,
	isComplianceTable = false,
	isMandateTable = false,
	customRowClickHandler = () => { },
	reformatRowDetailsForDrawer,
	...props
}) => {
	const [noData, setNoData] = React.useState(false)
	const [sorting, setSorting] = React.useState([])
	const [data, setData] = React.useState(initialTableData)

	const { loading, startLoading, stopLoading } = useLoadingStatus()
	const reloadToken = useSelector((state) => state.reloadToken)
	const [tablePaginationInfo, setTablePaginationInfo] = React.useState(DEFAULT_PAGINATION)
	const [openRowDetailsDrawer, setOpenRowDetailsDrawer] = useState(false)
	const [rowDetails, setRowDetails] = useState({})
	const dispatch = useDispatch()

	const lastVisitedControl = useSelector((state) => state.users.lastVisitedControl)

	// eslint-disable-next-line no-unused-vars
	const [totalSize, setTotalSize] = useState(0)
	const [exportData, setExportData] = useState([])
	const { searchBy, searchQuery } = useMemo(() => queryString.parseUrl(window.location.href).query, [window.location.href])
	const [searchField, setSearchField] = React.useState({
		searchBy: searchBy ? searchBy : '',
		text: searchQuery ? searchQuery : ''
	})
	const updatePage = () => {
		setTablePaginationInfo((state) => {
			return {
				...state,
				page: state.page + 1
			}
		})
	}

	const onRowClickCustomHandler = (state) => () => {
		setOpenRowDetailsDrawer(true)
		setRowDetails(typeof reformatRowDetailsForDrawer === 'function' ? reformatRowDetailsForDrawer(state) : state)
	}

	const onCustomRowClickHandler = (state) => () => {
		customRowClickHandler(state)
	}

	const handleCloseRowDetails = () => {
		setOpenRowDetailsDrawer(false)
	}

	//keys based filtering
	const filterObjectsByKeys = (arr, keys) => arr?.map(obj => keys.reduce((acc, key) => (key in obj && (acc[key] = obj[key]), acc), {}))
	const modifyDataSet = (arr, keys) =>
		arr?.map(item => {
			const newItem = { ...item };
			keys?.forEach((it) => {
				let modifiedValue = {};
				if (newItem.hasOwnProperty(it?.name)) {
					modifiedValue[it?.name] = it?.options?.exportAs(item)
					newItem[it?.name] = modifiedValue[it?.name]; // Apply dynamic transformation here
				}
			});
			return newItem;
		});

	const handleDataToExport = (params, count, columnsTemp = []) => {
		const updatedParams = {
			...params,
			page_size: count > 200 ? count : 200
		};

		const apiFunc = apiFuncService()
		apiFunc
			.api({
				params: updatedParams
			})
			.then((response) => {
				if (columnsTemp?.length > 0) {
					const columnsMap = columns?.map(item => item.name);
					const filteredArr = filterObjectsByKeys(response?.results?.data, columnsMap);
					// make the keys with export separate
					const modifiedDataKeys = columns?.filter((item) => item?.options?.exportAs);
					const filteredArrSet = modifiedDataKeys?.length > 0 ? modifyDataSet(response?.results?.data, modifiedDataKeys) : [];
					if (filteredArrSet?.length > 0) {
						setExportData(filteredArrSet)
					} else {
						setExportData(filteredArr)
					}
				}
				else if (isComplianceTable) {
					//Only for Pending Policy Table Export Data Handling 
					const temp = response?.results?.data?.flatMap(obj => obj.subcontrols);
					setExportData(temp)
				}
				else {
					setExportData(response?.results?.data)
				}
			})
	}

	const setStateFromResponse = (response, reset, params) => {
		stopLoading()
		if (response) {
			if (!response.error) {
				if (response?.results && response?.results?.total) {
					setTotalSize(response?.results?.total)
					if (isFullDatatoExport) {
						handleDataToExport(params, totalSize)
					} else {
						handleDataToExport(params, totalSize, columns)
					}
				}
				const items = response?.results?.data
					? enableCustomRestructureResponse
						? handleRestructureResponse(response.results.data)
						: response.results.data
					: []
				if (!reset && tablePaginationInfo.page > 1) {
					return setData((state) => state.concat(items))
				}
				setData(items)
			} else {
				if (tablePaginationInfo.page === DEFAULT_PAGINATION.page) {
					setTotalSize(0)
				}
			}
		}
	}

	const getSortKey = () => {
		const key = columns.find((col) => col.name === sorting[0].id).sortKey

		return `${sorting[0].desc ? '' : '-'}${key}`
	}

	const fetchData = (reset) => {
		if (reset) {
			setTablePaginationInfo(DEFAULT_PAGINATION)
			setData([])
		}
		dispatch(resetCompliancesState())

		const params = {
			...apiFuncQueryParams,
			page: reset ? DEFAULT_PAGINATION.page : tablePaginationInfo.page,
			page_size: reset ? DEFAULT_PAGINATION.rowsPerPage : tablePaginationInfo.rowsPerPage
		}

		if (searchField.text.length > 0) {
			params[searchField.searchBy] = searchField.text
		}

		if (sorting.length > 0) {
			params.sort_by = getSortKey()
		}

		startLoading()
		const apiFunc = apiFuncService()
		apiFunc
			.api({
				params
			})
			.then((response) => {
				setStateFromResponse(response, reset, params)
				if (searchBy && searchQuery && response?.results?.data?.length > 0 && response?.results?.data[0]?.subcontrols[0]) {
					dispatch(controlSearchReducer(response?.results?.data[0]?.subcontrols[0]))
				}
			})

		return apiFunc
	}

	const refreshState = () => {
		setData([])
		setNoData(false)
		setSearchField({
			searchBy: '',
			text: ''
		})
		setTablePaginationInfo(DEFAULT_PAGINATION)
	}

	const resetSearchResult = () => {
		setNoData(false)
		setSearchField({
			searchBy: '',
			text: ''
		})
		setTablePaginationInfo(DEFAULT_PAGINATION)
	}

	const fetchOnSearch = (searchBy, searchText) => {
		setNoData(false)
		setData([])
		startLoading()
		const queryForSearch = {
			searchBy,
			searchText
		}

		startLoading()
		const apiFunc = apiFuncService()
		const params = {
			...apiFuncQueryParams,
			[queryForSearch.searchBy]: queryForSearch.searchText,
			page: DEFAULT_PAGINATION.page,
			page_size: DEFAULT_PAGINATION.rowsPerPage
		}

		if (sorting.length > 0) {
			params.sort_by = getSortKey()
		}

		apiFunc
			.api({ params })
			.then((response) => {
				setStateFromResponse(response, true)
				setSearchField({
					searchBy,
					text: searchText
				})
				setTablePaginationInfo(DEFAULT_PAGINATION)
			})
			.catch(() => {
				stopLoading()
			})
		return apiFunc
	}

	React.useEffect(() => {
		refreshState()
		const requestToken = fetchData(true)

		return () => {
			requestToken.cancel()
		}
	}, [reloadToken, apiFuncService, JSON.stringify(apiFuncQueryParams), JSON.stringify(sorting), JSON.stringify(lastVisitedControl)])

	useUpdateEffect(() => {
		if (tablePaginationInfo.rowsPerPage < totalSize) {
			if (tablePaginationInfo.page > 0) {
				const requestToken = fetchData()

				return () => {
					requestToken.cancel()
				}
			}
		}
	}, [JSON.stringify(tablePaginationInfo), searchField, sorting])

	return (
		<>
			<Table
				columns={columns}
				data={data}
				totalSize={data.length === 0 ? 0 : totalSize < data.length ? data.length : totalSize}
				// totalSize={data?.length}
				fetchMore={updatePage}
				loading={loading}
				setSorting={setSorting}
				sorting={sorting}
				fetchOnSearch={fetchOnSearch}
				disableSearchBy={disableSearchBy}
				resetSearchResult={resetSearchResult}
				isComplianceTable={isComplianceTable}
				isMandateTable={isMandateTable}
				isEmpty={noData}
				dataToExport={exportData}
				disableMenu={exportData?.length > 0 ? false : true}
				disableFilter={disableFilter}
				options={{
					manualSorting: true
				}}
				showLoadMoreButton
				disableGroupBy={true}
				enableRowClick={detailsOnRowClick}
				onRowClickCustomHandler={isCustomRowClick ? onCustomRowClickHandler : onRowClickCustomHandler}
				{...props}
			/>
			{detailsOnRowClick && (
				<AutoRowDetails
					handleClose={handleCloseRowDetails}
					hiddenKeysForRowDetails={hiddenKeysForRowDetails}
					openDrawer={openRowDetailsDrawer}
					renderRowDetailsHeading={renderRowDetailsHeading}
					rowDetails={rowDetails}
					useAggregationsData
				/>
			)}
		</>
	)
}

export default ZeronDataTable
