import { AxiosService, ComplianceService, MitreAttackService } from '@interceptors/axiosInstance'

export const uploadComplianceDocs = (config) => {
	return AxiosService.post('/rest/v1/compliance/', config)
}

export const updateComplianceDocs = (id, config) => {
	return AxiosService.put(`/rest/v1/compliance/${id}/`, config)
}

export const uploadMandateDocs = (config) => {
	return AxiosService.post('/rest/v1/mandate-document/', config)
}

export const updateMandateDocs = (id, config) => {
	return AxiosService.put(`/rest/v1/mandate-document/${id}/`, config)
}

export const getAllDocs = (config) => {
	return AxiosService.get('/rest/v1/compliance/', config)
}

export const getAllMandateDocs = (config) => {
	return AxiosService.get('/rest/v1/mandate-document/', config)
}

export const deleteDocs = (id, config) => {
	return AxiosService.delete(`/rest/v1/compliance/${id}/`, config)
}

export const existingDocument = (doc, config) => {
	return AxiosService.delete(`/rest/v1/${doc}`, config)
}

export const deleteMandateDocs = (id, config) => {
	return AxiosService.delete(`/rest/v1/mandate-document/${id}/`, config)
}

export const getComplianceScore = (config) => {
	return AxiosService.post('/rest/v1/compliancescore/', config)
}

// export const getAllMandates = config => {
//     return AxiosService.get('/rest/v1/mandate/', config)
// }

export const getAllMandatesControls = (config) => {
	return AxiosService.get('/rest/v1/mandate-controls/', config)
}

export const getAllMandates = (config) => {
	return AxiosService.get('/rest/v1/mandate/', config)
}

export const getFrameworksList = (config) => {
	return AxiosService.get('/rest/v1/compliance-framework/', config)
}

export const getComplianceFrameworkListByType = (type, config) => {
	return AxiosService.get(`/rest/v1/compliance-framework/?type=${type}`, config)
}

export const getControlsByFrameworkId = (config) => {
	return AxiosService.get('/rest/v1/control/', config)
}

export const getMitreDetailsById = (config) => {
	return MitreAttackService.post(
		'/mitre/?fields=name,description,mitigations.name,mitigations.description,mitigations.mitigation_id,mitigations.labels,kill_chain_phases.phase_name',
		config
	)
}

export const getUpcomingSecurityAudit = (config) => {
	return AxiosService.get('/rest/v1/compliance-audit/', config)
}

export const deleteUpcomingSecurityAudit = (id, config) => {
	return AxiosService.delete('/rest/v1/compliance-audit/' + id + '/', config)
}

export const getMandateGap = (config) => {
	return AxiosService.post('/rest/v1/mandate_gap/', config)
}

export const createUpcomingSecurityAudit = (config) => {
	return AxiosService.post('/rest/v1/compliance-audit/', config)
}

export const editUpcomingSecurityAudit = (id, config) => {
	return AxiosService.put('/rest/v1/compliance-audit/' + id + '/', config)
}

export const updateUpcomingSecurityAudit = (config) => {
	return AxiosService.patch('/rest/v1/compliance-audit/', config)
}

export const getAllEvidences = (config) => {
	return AxiosService.get('/rest/v1/evidence-master/', config)
}

export const downloadAllEvidences = (config) => {
	return AxiosService.get('/rest/v1/evidence-download/', config)
}

export const downloadAllMandates = (config) => {
	return AxiosService.get('rest/v1/download-mandate-docs/', config)
}

export const downloadAllCompliance = (config) => {
	return AxiosService.get('rest/v1/download-compliance-docs/', config)
}

export const createEvidence = (config) => {
	return AxiosService.post('/rest/v1/evidence-master/', config)
}

export const updateEvidenceById = (id, config) => {
	return AxiosService.put(`/rest/v1/evidence-master/${id}/`, config)
}
//Deleting the Evidence:
export const deleteEvidenceById = (id, config) => {
	return AxiosService.delete(`rest/v1/evidence-master/${id}/`, config)
}
export const getAllImplementedAndUnimplementedControls = (config) => {
	return AxiosService.get('/rest/v1/document_implemented/', config)
}

export const getActiveIntegrationByRecommendedTool = (config) => {
	return AxiosService.get('/rest/v1/active-integration/', config)
}

export const getIntegrationtypemaster = (config) => {
	return AxiosService.get('/rest/v1/integrationtypemaster/', config)
}

export const getEvidenceBadges = (config) => {
	return AxiosService.get('/rest/v1/integrationtypemaster/', config)
}

export const ZScoreRemediationApi = (timeframe = '', config) => {
	return AxiosService.get(`/rest/v1/score-evolution/?timeframe=${timeframe}`, config)
}

//VRM Token
export const getVRMToken = (config) => {
	return AxiosService.get('/rest/v1/fetch-vrm-token/', config)
}

//VRM Overview KPI 
export const getVRMOverviewKPI = (config) => {
	return AxiosService.get('/rest/v1/fetch-vrm-overview/', config)
}

//VRM all vendors
export const getVRMAllVendorDetails = (config) => {
	return AxiosService.get('/rest/v1/fetch-vrm-all-vendors/', config)
}

//Compliance v1.5 apis::::

//All Compliance API
export const getAllCompliance = (config) => {
	return ComplianceService.get('rest/v1/compliance-v1.5/get-all-compliance/', config)
}

//Get Framework Control:
export const getFrameworkControl = (config) => {
	return ComplianceService.post('/rest/v1/compliance-v1.5/get-framework-control/', config)
}


//Put Fucntion ---> Compliance SOA ----> to be triggered if compliance.status is preset
export const applicableTrigger = (id, config) => {
	return AxiosService.put(`/rest/v1/compliance/${id}/`, config)
}

//Post Fucntion ----> Compliance SOA ----> to be triggered if compliance ==== {}
export const applicableTriggerConditional = (config) => {
	return AxiosService.post('/rest/v1/compliance/', config)
}