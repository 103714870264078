export const permissionKeys = {
	// To enable cloud monitor in sidebar
	AwsScreen: 'AwsScreen',
	AzureScreen: 'AzureScreen',
	GcpScreen: 'GcpScreen',
	CloudConfigurationScreen: 'CloudConfigurationScreen',

	// To enable Business posture in sidebar
	BusinessRiskPostureScreen: 'BusinessRiskPostureScreen',
	BusinessRiskPostureFinancialFactorsTab: 'BusinessRiskPostureFinancialFactorsTab',
	BusinessRiskPostureFinancialsTab: 'BusinessRiskPostureFinancialsTab',
	PostureStatusScreen: 'PostureStatusScreen',
	PostureAddZScoreScreen: 'PostureAddZScoreScreen',
	BusinessAssessmentButton: 'BusinessAssessmentButton',
	RBIMandatesOverviewScreen: 'RBIMandatesOverviewScreen',
	RbiSarMandatesOverviewScreen: 'RbiSarMandatesOverviewScreen',
	RbiPapgMandatesOverviewScreen: 'RbiPapgMandatesOverviewScreen',
	RbiItgrcMandatesOverviewScreen: 'RbiItgrcMandatesOverviewScreen',
	SebiMandatesOverviewScreen: 'SebiMandatesOverviewScreen',
	CertInMandatesOverviewScreen: 'CertInMandatesOverviewScreen',
	IrdaiMandatesOverviewScreen: 'IrdaiMandatesOverviewScreen',
	RmitScreen: 'RmitScreen',
	UAEIAR: 'UAEIAR',

	//CRQ Permissions:
	CRQControls: 'CRQControls',
	CRQControlDetails: 'CRQControlDetails',
	CRQOverviewScreen: 'CRQOverviewScreen',
	CRQLOBAlanysis: 'CRQLOBAlanysis',
	BusinessPostureManagement: 'BusinessPostureManagement',

	//Integration Overview Tabs
	IntegrationOverview: 'IntegrationOverview',
	LobAnalysis: 'LobAnalysis',
	GranularQuery: 'GranularQuery',

	// To enable Compliance in sidebar
	GdprScreen: 'GdprScreen',
	PciDssScreen: 'PciDssScreen',
	NistScreen: 'NistScreen',
	HippaScreen: 'HippaScreen',
	SebiCloudServicesMandatesOverviewScreen: 'SebiCloudServicesMandatesOverviewScreen',
	BSPMandatesOverviewScreen: 'BSPMandatesOverviewScreen',
	Soc2Screen: 'Soc2Screen',
	CSAScreen: 'CSAScreen',
	Iso27001Screen: 'Iso27001Screen',
	Iso27701Screen: 'Iso27701Screen',
	Iso270012022Screen: 'Iso270012022Screen',
	ComplianceOverviewScreen: 'ComplianceOverviewScreen',
	MandateOverviewScreen: 'MandateOverviewScreen',
	VendorPulseScreen: 'VendorPulseScreen',
	VendorPulseControlPanelButton: 'VendorPulseControlPanelButton',
	AuditReadinessScreen: 'AuditReadinessScreen',
	EvidenceDetailsScreen: 'EvidenceDetailsScreen',
	ManageStandardsAndMandates: 'ManageStandardsAndMandates',


	//to let user change the status from draft to publish
	// PolicyApproval: 'PolicyApproval',

	//to anable industry benchamrks
	IndustryBenchmarks: 'IndustryBenchmarks',

	// To enable Others in sidebar
	ManagementScreen: 'ManagementScreen',
	IntegrationHomeScreen: 'IntegrationHomeScreen',
	IntegrationScreen: 'IntegrationScreen',
	Zensor: 'Zensor',
	ManageZensor: 'ManageZensor',
	AdministrationScreen: 'AdministrationScreen',
	HelpScreen: 'HelpScreen',
	AddTargetScreen: 'AddTargetScreen',
	ManageAttackSurfaceButton: 'ManageAttackSurfaceButton',

	// To enable Defence in sidebar
	RiskMitigationScreen: 'RiskMitigationScreen',
	IntegrationViewScreen: 'IntegrationViewScreen',
	SecurityEventsNestedScreen: 'SecurityEventsNestedScreen',
	HostNestedScreen: 'HostNestedScreen',
	DetectionNestedScreen: 'DetectionNestedScreen',
	IncidentResponseNestedScreen: 'IncidentResponseNestedScreen',
	CspmOverviewScreen: 'CspmOverviewScreen',
	AttackSurfaceVaptReports: 'AttackSurfaceVaptReports',

	// This permission is used to display the SSO setup component
	SSO: 'SSO',

	//This is the ASM2.0 Permission:
	SecurityAssessmentScreen2: 'SecurityAssessmentScreen2',
	ASMSchedularManagement: 'ASMSchedularManagement',
	ASMLOBManagement: 'ASMLOBManagement',
	CloudBucketScreen: 'CloudBucketScreen',
	LookalikeDomainScreen: 'LookalikeDomainScreen',
	VulRemedyScreen: 'VulRemedyScreen',

	//Attack Surface v3.0
	AttackSurfaceV3: 'AttackSurfaceV3',
	LookAlikeDomain: 'LookAlikeDomain',
	CloudBucket: 'CloudBucket',

	//admin page permissions
	AttackSurfaceV3Management: 'AttackSurfaceV3Management',
	AttackSurfaceV3View: 'AttackSurfaceV3View',
	AttackSurfaceV3Schedular: 'AttackSurfaceV3Schedular',
	AttackSurfaceV3LOB: 'AttackSurfaceV3LOB',
	AddTargetScreenV3: 'AddTargetScreenV3',


	//ReportScreen::
	ReportGenerationScreen: 'ReportGenerationScreen',
	ReportCreationScreen: 'ReportCreationScreen'
}

export const dependentPermissionsForSidebarGrouping = {
	CloudManagerScreen: [permissionKeys.CspmOverviewScreen, permissionKeys.AwsScreen, permissionKeys.AzureScreen, permissionKeys.GcpScreen, permissionKeys.CloudConfigurationScreen],

	BusinessRiskPostureScreenParent: [
		permissionKeys.BusinessRiskPostureScreen,
		permissionKeys.BusinessRiskPostureFinancialFactorsTab,
		permissionKeys.BusinessRiskPostureFinancialsTab,
		permissionKeys.PostureStatusScreen,
		permissionKeys.PostureAddZScoreScreen,
		// permissionKeys.BusinessAssessmentButton,
	],

	BusinnessPosturev2Parent: [
		permissionKeys.CRQControls,
		permissionKeys.CRQControlDetails,
		permissionKeys.CRQOverviewScreen,
		permissionKeys.CRQLOBAlanysis
	],

	MandateOverviewScreen: [
		permissionKeys.RBIMandatesOverviewScreen,
		permissionKeys.RbiSarMandatesOverviewScreen,
		permissionKeys.RbiPapgMandatesOverviewScreen,
		permissionKeys.SebiMandatesOverviewScreen,
		permissionKeys.CertInMandatesOverviewScreen,
		permissionKeys.IrdaiMandatesOverviewScreen,
		permissionKeys.RmitScreen,
		permissionKeys.UAEIAR
	],

	SecurityAssessmentScreen2: [
		permissionKeys.SecurityAssesmentOverViewScreen,
		permissionKeys.SecurityAssessmentAssetViewScreen,
		permissionKeys.SecurityAssessmentFindingViewScreen
	],

	AttackSurfaceV3: [
		permissionKeys.Overview,
		permissionKeys.Asset,
		permissionKeys.Findings,
		permissionKeys.DeepInsights
	],

	ComplianceScreen: [
		permissionKeys.GdprScreen,
		permissionKeys.PciDssScreen,
		permissionKeys.NistScreen,
		permissionKeys.HippaScreen,
		permissionKeys.SebiCloudServicesMandatesOverviewScreen,
		permissionKeys.BSPMandatesOverviewScreen,
		permissionKeys.Soc2Screen,
		permissionKeys.CSAScreen,
		permissionKeys.Iso27001Screen,
		permissionKeys.Iso27701Screen,
		permissionKeys.Iso270012022Screen,
		permissionKeys.ComplianceOverviewScreen,
		permissionKeys.VendorPulseScreen,
		permissionKeys.AuditReadinessScreen,
		permissionKeys.MandateOverviewScreen
	],

	ManagementScreenParent: [
		permissionKeys.ManagementScreen,
		permissionKeys.AdministrationScreen,
		permissionKeys.HelpScreen
	],

	SecurityOverviewScreen: [
		permissionKeys.RiskMitigationScreen,
		permissionKeys.IntegrationViewScreen,
		permissionKeys.SecurityEventsNestedScreen,
		permissionKeys.HostNestedScreen,
		permissionKeys.DetectionNestedScreen,
		permissionKeys.IncidentResponseNestedScreen
	]
}

export const dependentPermissionsForRoutes = {
	EvidenceDetailsScreen: [permissionKeys.AuditReadinessScreen]
	// Cspm2OverviewScreen: [permissionKeys.CspmOverviewScreen]
}
