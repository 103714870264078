import { AxiosService, CRQv2Service } from "@interceptors/axiosInstance"

//General API:
//1. Get Analysis Details:
export const fetchAnalysisDetails = (config) => {
    return CRQv2Service.get('/crq/v1/simulation/simulation_details', config)
}
//2. Run analysis:
export const RunAnalysisAPI = (config) => {
    return CRQv2Service.post('/crq/v1/simulation/run_simulation', config)
}
//3. Get Status API :
export const fetchCRQStatus = (config) => {
    return CRQv2Service.get('/crq/v1/simulation/status', config)
}

//For the Assessment Page:::
// For Posting the Questionnaire:
export const postOrganisationAnswer = (config) => {
    return CRQv2Service.post('/crq/v1/assessment/organization_answers', config)
}
//1. For the Upload Logo of the organisation:
export const getOrganisationAnswer = (config) => {
    return CRQv2Service.get('/crq/v1/assessment/organization_answers', config)
}
//2. For the Upload Logo of the organisation:
export const uploadCompanyLogo = (config) => {
    return CRQv2Service.post('/crq/v1/upload/upload_logo/', config)
}
//3. For Stock Ticker ID:
export const fetchTickerDetails = (tickerID, config) => {
    return CRQv2Service.get(`/crq/v1/assessment/get_ticker_information?symbol=${tickerID}`, config)
}
//4. For Industry Suggestion:
export const getIndustryAndCountryCode = (config) => {
    return CRQv2Service.get(`/crq/v1/assessment/suggestions`, config)
}
//For the OverView Page:::
export const getLOBDetails = (config) => {
    return CRQv2Service.get('/crq/v1/overview/get_all_lobs', config)
}

export const getThreatsData = (config) => {
    return CRQv2Service.get('/crq/v1/overview/all_threats', config)
}

export const OrgMetrics = (config) => {
    return CRQv2Service.get('/crq/v1/overview/organizational_metrics/', config)
}

export const getMandateInconsistencyDetails = (config) => {
    return AxiosService.post('/rest/v1/mandate_gap_ver2/', config)
}

export const getZScoreValue = (config) => {
    return AxiosService.post('/rest/v1/z-score/', config)
}

export const getZscoreEvolution = (config) => {
    return AxiosService.get('/rest/v1/score-evolution/', config)
}

export const getMetricsData = (config) => {
    return CRQv2Service.get('/crq/v1/overview/organizational_metrics', config)
}

//For the Deep Analysis Page:

export const getallDomainList = (config) => {
    return CRQv2Service.get('/crq/v1/deep_insights/all_domains', config)
}

export const getMitigationGraph = (config) => {
    return CRQv2Service.get('/crq/v1/deep_insights/mitigation_trends/', config)
}

export const fetchRiskMatrixDetails = (config) => {
    return CRQv2Service.get('/crq/v1/deep_insights/risk_matrix/', config)
}

//For the LOB Analysis Page:
export const fetchLOBMetricData = (config) => {
    return CRQv2Service.get('/crq/v1/lob_analysis/lob_metrics/', config)
}
export const fetchLOBThreatData = (config) => {
    return CRQv2Service.get('/crq/v1/lob_analysis/all_threats', config)
}

export const getLOBMitigationGraph = (config) => {
    return CRQv2Service.get(`/crq/v1/lob_analysis/mitigation_trends`, config)
}

export const fetchDomainAndThreatGraph = (config) => {
    return CRQv2Service.get(`/crq/v1/lob_analysis/unique_domains_and_threats`, config)
}

export const fetchLOBRiskMatrixDetails = (config) => {
    return CRQv2Service.get('/crq/v1/lob_analysis/risk_matrix/', config)
}