import { validAscents } from '@constants/propTypesValidation'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { FormControl, TextField } from '@mui/material'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { getUuid } from '@utils/other'
import PropTypes from 'prop-types'
import React from 'react'
const filter = createFilterOptions()

const AutoCompleteBox = ({
	// ascent = 'primary',
	required,
	parentClassName,
	autoCompleteClasses,
	className,
	id = `autocomplete-input-${getUuid()}`,
	value = '',
	onChange = () => {},
	fullWidth = false,
	label,
	readOnly = false,
	options = [],
	textFieldProps,
	canAddNewOption = false,
	minWidth,
	groupBy,
	...props
}) => {
	const getOptionLabel = React.useCallback((option) => option?.label || '', [])

	const getOptionDisabled = React.useCallback((option) => option?.disabled, [])

	const filterOptions = React.useMemo(() => {
		return canAddNewOption
			? (options, params) => {
					const filtered = filter(options, params)

					// Suggest the creation of a new value
					if (params.inputValue !== '') {
						filtered.push({
							value: params.inputValue,
							label: params.inputValue
						})
					}

					return filtered
			  }
			: undefined
	}, [canAddNewOption])

	const handleGroupBy = React.useCallback((option) => option[groupBy], [groupBy])

	const handleEnter = (e) => {
		if(e.key){
			e.preventDefault()
		}
	}

	const Input = (params) => (
		<TextField
			{...params}
			className={className}
			label={!!label && (required ? `${label} *` : label)}
			InputLabelProps={{
				sx: {
					color: (theme) => theme.palette.ascents.primary,
					fontSize: '0.875rem',
					'&.MuiInputLabel-shrink': {
						color: (theme) => theme.palette.ascents.primary
					},
					'&.MuiInputLabel-asterisk': {
						color: (theme) => theme.palette.ascents.orange
					}
				}
			}}
			variant='standard'
			showLabel
			{...textFieldProps}
		/>
	)

	return (
		<FormControl
			fullWidth
			required={required}
			{...props}
			className={parentClassName}
			sx={{
				marginTop: 0,
				minWidth,
				...props.sx
			}}
		>
			<Autocomplete
				groupBy={handleGroupBy}
				limitTags={3}
				options={options}
				id={id}
				value={value}
				onChange={onChange}
				onKeyDown={handleEnter}
				disabled={readOnly}
				className={autoCompleteClasses}
				fullWidth={fullWidth}
				selectOnFocus
				clearOnBlur
				handleHomeEndKeys
				filterOptions={filterOptions}
				getOptionLabel={getOptionLabel}
				getOptionDisabled={getOptionDisabled}
				isOptionEqualToValue={(option, value) => JSON.stringify(option) === JSON.stringify(value)}
				renderInput={Input}
				disableClearable={!value}
				disableCloseOnSelect={props.multiple}
				popupIcon={<ExpandMoreIcon />}
				{...props}
				sx={{
					'& .MuiIconButton-root': {
						padding: '4px'
					},
					'& .MuiAutocomplete-clearIndicator': {
						marginRight: '0.5rem'
					},
					'& .MuiAutocomplete-endAdornment': {
						top: '50%',
						transform: 'translateY(-50%)'
					},
					...props?.sx
				}}
				freeSolo={canAddNewOption}
			/>
		</FormControl>
	)
}

AutoCompleteBox.propTypes = {
	ascent: validAscents,
	onChange: PropTypes.func,
	options: PropTypes.array,
	canAddNewOption: PropTypes.bool
}

export default React.memo(AutoCompleteBox)
