import Text from '@commons/Text'
import { Box } from '@mui/material'
import { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

const ReferencesList = ({ references = [], viewOnly = false }) => {
	const maxShowCount = useMemo(() => (references.length > 2 ? 2 : references.length), [references])
	const [showAll, setShowAll] = useState(references.length <= maxShowCount)

	const handleToggleShowAll = () => setShowAll(!showAll)

	return (
		<Box
			onClick={(e) => e.stopPropagation()}
			component={'ol'}
			sx={{
				m: 0,
				p: 0,
				'& li': {
					mb: 2,
					whiteSpace: 'nowrap',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					maxWidth: '100%'
				},
				'& a': {
					ml: 0.5
				}
			}}
		>
			{(!showAll ? references.slice(0, maxShowCount) : references).map((reference, index) => (
				<li key={reference}>
					{!viewOnly && (
						<>
							{index + 1}.
							<Link target='_blank' to={reference}>
								{reference}
							</Link>
						</>
					)}
					{viewOnly && (
						<>
							<Box display={'flex'} gap={2}>
								{index + 1}.
								<Text fontSize={12} fontWeight={700} fontFamily='Open Sans' cursor='default'>
									{reference}
								</Text>
							</Box>
						</>
					)}
				</li>
			))}
			{!showAll && (
				<Text fontWeight={700} fontFamily='Open Sans' onClick={handleToggleShowAll} cursor='pointer' margin='1rem 0 0 0'>
					Show all
				</Text>
			)}
		</Box>
	)
}

export default ReferencesList
