import Button from '@commons/Button/Button'
import WrapperCard from '@commons/Card/WrapperCard'
import Icon from '@commons/Icon'
import Loader from '@commons/Loader'
import AppModal from '@commons/Modal/AppModal'
import RenderControlStatus from '@commons/RenderControlStatus'
import ZeronDataTable from '@commons/Table/ZeronDataTable'
import Text from '@commons/Text'
import { complianceFrameworkTypes } from '@constants/Compliance/complianceConstants'
import { customEvents } from '@constants/analytics'
import { Delete } from '@mui/icons-material'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import EditIcon from '@mui/icons-material/Edit'
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom'
import TungstenIcon from '@mui/icons-material/Tungsten'
import UploadIcon from '@mui/icons-material/Upload'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { Box, IconButton, Tooltip } from '@mui/material'
import { getControlsByFrameworkId } from '@services/complianceService'
import { ASCENTS } from '@styles/theme'
import { formatDateTime, getZinStatus } from '@utils/other'
import mixpanel from 'mixpanel-browser'
import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

const CompliancePendingPolicies = ({
	membersById = {},
	deletePoliciesDocument,
	handleEditUploadDocument,
	isLoading,
	frameworkId,
	pendingPolicyList = []
}) => {
	const [isOpenModal, setIsModalOpen] = useState(false)
	const [isSubModalOpen, setIsSubModalOpen] = useState(false)
	// const [subModalData, setSubModalData] = useState()
	const [rowDetails, setRowDetails] = useState([])
	const [messageBox, setMessageBox] = useState([])

	const onRowClickCustomHandler = (state) => () => {
		setIsModalOpen(true)
		setRowDetails(state)
	}

	const handleClose = () => {
		if (!isOpenModal) {
			mixpanel.time_event(customEvents.TOGGLED_MODAL)
		} else {
			mixpanel.track(customEvents.TOGGLED_MODAL, {
				url: window.location.pathname,
				title: `Compliance Document Details`
			})
		}
		setIsModalOpen(false)
	}

	const columns = useMemo(
		() => [
			{
				name: 'control_details.ref_code',
				// sortKey: 'control__ref_code',
				label: 'Control Id',
				searchKey: 'ref_code',
				options: {
					enableSorting: false,
					sort: false,
					// viewColumns: false,
					filter: false,
					print: false,
					cell: (info) => {
						const value = info.getValue()
						return value ? value : 'N/A'
					}
				}
			},
			{
				name: 'control_details.category',
				sortKey: '',
				// enableSorting: false,
				// searchKey: '',
				label: 'Control Category',
				options: {
					enableSorting: false,
					sort: false,
					// viewColumns: false,
					filter: false,
					print: false,
					cell: (info) => {
						const value = info.getValue()
						return value ? value : 'N/A'
					}
				}
			},
			{
				name: 'compliance.assigned_to',
				label: 'Assigned to',
				// sortKey: 'assigned_to__email',
				searchKey: '',
				options: {
					enableSorting: false,
					cell: (info) => {
						const value = Number(info.getValue())
						return Object.keys(membersById).length > 0 ? (
							<Text fontWeight={value && !!membersById[value] ? 600 : 400} fontSize={12}>
								{value && !!membersById[value] ? membersById[value]?.username : 'Not assigned'}
							</Text>
						) : (
							<Text fontSize={12}>Loading...</Text>
						)
					},
					exportAs: (info) => {
						const value = info.compliance.assigned_to
						const userName =
							value && !!membersById[value]
								? `${membersById[value]?.first_name} ${membersById[value]?.last_name}`
								: 'Not assigned'
						return userName
					}
				}
			},
			{
				name: 'compliance.status',
				label: 'Status',
				// sortKey: 'status',
				// searchKey: '',
				options: {
					enableSorting: false,
					cell: (info) => {
						const value = info.getValue()
						return <RenderControlStatus value={value ? value : 'Pending'} />
					},
					exportAs: (info) => {
						const value = info.compliance?.status
						return value ? value : 'Pending'
					}
				}
			},
			{
				name: 'compliance.date',
				label: 'Date',
				// sortKey: 'date',
				options: {
					enableSorting: false,
					cell: (info) => {
						const value = info.getValue()
						return value && value !== '--'
							? formatDateTime(value, {
								format: 'DD MMMM YYYY'
							})
							: '---'
					},
					exportAs: (info) => {
						const value = info.compliance.date
						return value && value !== '--'
							? formatDateTime(value, {
								format: 'DD MMMM YYYY'
							})
							: '---'
					}
				}
			},
			{
				name: 'control_details.description',
				label: 'Description',
				searchKey: 'description',
				sortKey: 'description',
				options: {
					enableSorting: false,
					colSpan: 2,
					cell: (info) => info.row.original?.description
				}
			},
			{
				name: 'compliance.zen_ai.status',
				label: 'ZIN',
				options: {
					enableSorting: false,
					cell: (info) => {
						const value = info.getValue()
						return value == 2 ? (
							<>
								<Box display='flex'>
									{/* <ChecklistIcon
										sx={{
											marginRight: '10px',
											fontSize: '1.2rem',
											color: `${ASCENTS.primary} !important`
										}}
									/> */}
									<TungstenIcon
										sx={{
											transform: 'rotate(180deg)',
											marginRight: '10px',
											fontSize: '1.2rem',
											color: `${ASCENTS.primary} !important`,
											'@keyframes blink': {
												'50%': {
													opacity: '0.0'
												}
											},
											animation: 'blink 1s step-start 0s infinite'
										}}
									/>
									<Button
										variant='text'
										sx={{ fontWeight: '700 !important', padding: 0, textAlign: 'start' }}
										onClick={(e) => {
											setMessageBox(info.row.original?.compliance?.zen_ai.message)
											setIsSubModalOpen(true),
												// setSubModalData(info.row.original?.compliance?.zen_ai?.remarks),
												e.stopPropagation()
											console.log('info:::', messageBox)
										}}
									>
										Suggestions by ZIN
										{/* {value} */}
									</Button>
								</Box>
							</>
						) : (
							<Text>
								{value ? (
									value == 3 ? (
										<>
											<Box display='flex'>
												<DoneAllIcon
													sx={{
														marginRight: '10px',
														fontSize: '1.2rem',
														color: `${ASCENTS.green} !important`
														// animation: 'openclose 3s ease-in-out infinite',
														// '@keyframes openclose': {
														// 	'0%': {
														// 		width: '0%'
														// 	},
														// 	'15%': {
														// 		width: '15%'
														// 	}
														// }
													}}
												/>
												<Text sx={{ color: (theme) => theme.palette.ascents.green }}>
													Congrats your document is ready to be Published !
												</Text>
												{/* {value} */}
											</Box>
										</>
									) : (
										<>
											<Box display='flex'>
												<HourglassBottomIcon
													sx={{
														marginRight: '10px',
														fontSize: '1.2rem',
														color: `${ASCENTS.orange} !important`,
														'@keyframes rotate': {
															'0%': {
																transform: 'rotate(-180deg)'
															},
															'100%': {
																transform: 'rotate(180deg)'
															}
														},
														animation: 'rotate 2s infinite'
													}}
												/>
												<Text sx={{ color: (theme) => theme.palette.ascents.orange }}>
													Document being reviewed by ZIN
												</Text>
											</Box>
											{/* {value} */}
										</>
									)
								) : (
									<>
										<Box
											display='flex'
											onClick={handleEditUploadDocument(
												info.row.original,
												Object.keys(info.row.original?.compliance).length === 0 ? false : true
											)}
										>
											{info?.row?.original?.compliance?.status != 'Not Applicable' && <><UploadIcon
												sx={{
													marginRight: '10px',
													fontSize: '1.2rem'
												}}
											/>
												<Text>Upload a Document</Text></>
											}
										</Box>
									</>
								)}
							</Text>
						)
					},
					exportAs: (info) => {
						const value = info.compliance?.zen_ai?.status
						return getZinStatus(value)
					}
				}
			},
			{
				name: 'id',
				label: 'Action',
				options: {
					enableSorting: false,
					cell: (info) => {
						const value = info.getValue()
						return (
							value && (
								<>
									{info.row.original?.compliance?.documents?.length > 0 && (
										<Tooltip
											arrow
											placement='left'
											title={
												<Box overflow={'scroll'} maxHeight={'25rem'} minWidth={'15rem'} maxWidth={'30rem'} p={2}>
													<Text fontSize={16} fontWeight={600} mb={2} sx={{ color: `${ASCENTS.primary}` }}>
														All Uploaded Documents
													</Text>
													{info.row.original?.compliance?.documents?.map((ele) => {
														const fileName = decodeURI(
															ele.replace('devzeron/compliance_documents/', '').split('?')[0].split('/').pop()
														)
														return (
															<>
																<Box display='flex' justifyContent={'space-between'} alignItems={'center'}>
																	<Tooltip
																		title='Depending on the compatibility of the browser, the uploaded file will either
														download or open.'
																	>
																		<Link
																			underline='always'
																			to={ele}
																			target='_blank'
																			onClick={(e) => e.stopPropagation()}
																		>
																			<Text fontSize={16}>{fileName}</Text>
																		</Link>
																	</Tooltip>
																	<Box>
																		<IconButton
																			marginLeft='1rem'
																			// padding={'0.375rem 1rem'}
																			onClick={
																				((e) => e.stopPropagation(),
																					deletePoliciesDocument(ele, info.row.original?.compliance))
																			}
																		>
																			<Icon fontSize={'1rem'}>
																				<Delete />
																			</Icon>
																		</IconButton>
																	</Box>
																</Box>
															</>
														)
													})}
												</Box>
											}
										>
											<IconButton padding={'0.375rem 1rem'}>
												<Icon fontSize={'1rem'}>
													<VisibilityIcon />
												</Icon>
											</IconButton>
										</Tooltip>
									)}
									{info?.row?.original?.compliance?.status != 'Not Applicable' && <IconButton
										padding={'0.375rem 1rem'}
										onClick={handleEditUploadDocument(
											info.row.original,
											Object.keys(info.row.original?.compliance).length === 0 ? false : true
										)}
									>
										<Icon fontSize={'1rem'}>
											<EditIcon />
										</Icon>
									</IconButton>}
								</>
							)
						)
					},
					filter: false,
					print: false,
					searchable: false,
					sort: false,
					download: false,
					exportAs: (info) => {
						return info.document
					}
				}
			}
		],
		[membersById, JSON.stringify(pendingPolicyList)]
	)

	const toggleSubModal = () => {
		setIsSubModalOpen(!isSubModalOpen)
	}

	const handleRestructureResponse = (data) => {
		let finalData = []

		data.map((each) => {
			each.subcontrols.map((control) => {
				finalData = [
					...finalData,
					{
						...control,
						control_details: {
							ref_code: control.ref_code,
							id: control.id,
							description: control.description,
							category: control.category
						}
					}
				]
			})
		})

		return finalData
	}

	return isLoading || Object.keys(membersById).length === 0 ? (
		<Box display='flex' justifyContent='center' alignItems='center' height='100%'>
			<Loader />
		</Box>
	) : (
		<>
			<WrapperCard>
				<ZeronDataTable
					columns={columns}
					apiFuncService={getControlsByFrameworkId}
					apiFuncQueryParams={{
						framework_id: frameworkId,
						type: complianceFrameworkTypes.Compliance,
						exclude_existing: 1
					}}
					disableFilter
					isComplianceTable={true}
					enableCustomRestructureResponse
					handleRestructureResponse={handleRestructureResponse}
					enableRowClick
					onRowClickCustomHandler={onRowClickCustomHandler}
				/>
				<AppModal fullWidth maxWidth='md' hideClose open={isOpenModal} onClose={handleClose} title='Document details'>
					<Text ascent='primary' fontWeight='500' fontSize='1.25rem'>
						Control Id
					</Text>

					<Text margin='0 0 1rem 1rem'>{rowDetails?.control_details?.ref_code || 'N.A'}</Text>

					<Text ascent='primary' fontWeight='500' fontSize='1.25rem'>
						Description
					</Text>

					<Text margin='0 0 1rem 1rem'>{rowDetails?.control_details?.description || 'N.A'}</Text>

					<Text ascent='primary' fontWeight='500' fontSize='1.25rem'>
						Category
					</Text>

					<Text margin='0 0 1rem 1rem'>{rowDetails?.control_details?.category || 'N.A'}</Text>
				</AppModal>
				<AppModal fullWidth maxWidth='md' hideClose open={isSubModalOpen} onClose={toggleSubModal} title='ZIN Suggestions'>
					{/* {subModalData?.map((ele, key) => (
						<>
							<Box display='flex' m={1}>
								<Text ascent='primary' fontWeight='500' fontSize='1.25rem' sx={{ marginRight: '0.8rem' }}>
									{key + 1}.{''}
								</Text>
								<Text fontWeight='400' fontSize='1.25rem'>
									{ele}
								</Text>
							</Box>
						</>
					))} */}
					{messageBox?.map((ele, key) => (
						<>
							<Box display='flex' m={1}>
								<Text ascent='primary' fontWeight='500' fontSize='1rem' sx={{ marginRight: '0.8rem' }}>
									{key + 1}.{''}
								</Text>
								<Text fontWeight='400' fontSize='1rem'>
									{ele.filename}
								</Text>
							</Box>
							<Box display='flex' m={1}>
								<Text>
									{ele.suggestion.map((item, index) => (
										<ul key={index}>
											<li>{item}</li>
										</ul>
									))}
								</Text>
							</Box>
						</>
					))}
				</AppModal>
			</WrapperCard>
		</>
	)
}

export default CompliancePendingPolicies
