import Button from '@commons/Button/Button'
import WrapperCard from '@commons/Card/WrapperCard'
import { CustomErrorMessage } from '@commons/GenerateFormHookControllers'
import AutoCompleteBox from '@commons/Inputs/AutoCompleteBox'
import FileInput from '@commons/Inputs/FileInput'
import SelectBox from '@commons/Inputs/SelectBox'
import TextBox from '@commons/Inputs/TextBox'
import Text from '@commons/Text'
import { IMAGE_FILE } from '@constants/formInputTypes'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { Chip, FormControlLabel, Grid, LinearProgress, Radio, RadioGroup, Tooltip } from '@mui/material'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { fetchTickerDetails, getIndustryAndCountryCode, getOrganisationAnswer, postOrganisationAnswer, uploadCompanyLogo } from '@screens/CRQ2/services/crqservices'
import { enqueueSnackbar } from 'notistack'
import React, { useEffect, useMemo, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import './css/crq.css'
export default function QuestionComponent(props) {
	const [prevData, setPrevData] = React.useState([])
	const {
		control,
		formState: { errors },
		setValue,
		handleSubmit,
		getValues,
		setError,
		clearErrors,
		watch
	} = useForm()



	const allFormDataValues = getValues()
	const [tickerFlag, setTickerFlag] = React.useState(false)
	const [listofLOB, setListofLOB] = React.useState([])
	const [timeoutId, setTimeoutId] = React.useState(null)
	const [lobPreset, setLOBPresent] = React.useState('')
	const [publiclyListed, setPubliclyListed] = React.useState([])
	const [nextId, setNextID] = React.useState(0)
	const [employeeStrength, setEmployeeStrength] = React.useState([])
	const [optionsList, setOptionsList] = React.useState([])
	const [isEditModeLob, setIsEditModeLob] = React.useState({})
	const [editModeRevenue, setEditModeRevenue] = React.useState(Array(allFormDataValues?.lobDetails?.length).fill(false))
	const [tickerDetails, setTickerDetails] = React.useState([])
	const [selectedOption, setSelectedOption] = React.useState(prevData?.line_of_business_flag || '')
	const { fields: lobFields, remove: removeLob } = useFieldArray({
		control,
		name: 'lobDetails'
	})

	//File Upload Declaration::
	const [logoLink, setLogoLink] = React.useState('')
	const [file, setFile] = React.useState([])

	const [fileNames, setFileNames] = useState({});

	// const handleFileChange = (e, index, onChange, name) => {
	// 	const selectedFile = e[0];
	// 	if (selectedFile) {
	// 		setFileNames((prev) => ({
	// 			...prev,
	// 			[index]: selectedFile.path, // Save the file name based on the index
	// 		}));
	// 		setFile(selectedFile)
	// 		onChange(selectedFile);
	// 		console.log('file upload', selectedFile.path);
	// 		clearErrors(name);
	// 	}
	// };


	const fileUpload = (e, index, onChange, name) => {
		const selectedFile = e[0]
		if (selectedFile) {
			console.log("This is the file for upload", e)
			const formData = new FormData()
			formData.append('file', selectedFile)
			const uploadLogoAPI = uploadCompanyLogo()
			if (formData) {
				uploadLogoAPI.api({
					data: formData
				}).then((response) => {
					if (response) {
						if (!response.error) {
							enqueueSnackbar('The Upload is Successfull.', { variant: 'success' })
							console.log("The response after upload", response)
							setFileNames((prev) => ({
								...prev,
								[index]: response?.data?.logo_url, // Save the file name based on the index
							}));
							setFile((prev) => ({
								...prev,
								[index]: response?.data?.file_name, // Save the file name based on the index
							}));
							onChange(fileNames[index])
							clearErrors(name);
						}
					}
				})
			}
		}
	}

	const handleReset = (index, onChange, name) => {
		setFileNames((prev) => ({
			...prev,
			[index]: null, // Reset the file name for the specific index
		}));
		onChange(null); // Reset the selected file
		setFile((prev) => ({
			...prev,
			[index]: null, // Reset the file name for the specific index
		}));
		clearErrors(name); // Clear any errors related to the file input
		setLogoLink(null);
	};

	const handleRadioChange = (event) => {
		const newValue = event.target.value
		setValue('line_of_business_flag', newValue)
		setSelectedOption(newValue) // Update local state
		props.onFlagChange(newValue === 'Yes') // Assume onFlagChange expects a boolean
		setListofLOB([]) // Example of resetting dependent state
		setLOBPresent(newValue) // Update any state that depends on this option
	}

	const toggleEditModeLob = (index) => {
		setIsEditModeLob((prev) => ({
			...prev,
			[index]: !prev[index]
		}))
	}

	const toggleEditModeRevenue = (index) => {
		setEditModeRevenue((prev) => ({
			...prev,
			[index]: !prev[index]
		}))
	}

	const LobSegments = ({ lobIndex, allFormData }) => {
		const { control } = useForm()
		const { fields, append, remove } = useFieldArray({
			control,
			name: `lobDetails[${lobIndex}].segments`
		})

		const [lobTotalPercentages, setLobTotalPercentages] = useState([])

		const calculateTotalPercentage = () => {
			const allValues = getValues()
			const totalPercentage = allValues.lobDetails[lobIndex].segments.reduce((total, segment) => {
				return total + parseFloat(segment.revenuePercentage || 0)
			}, 0)
			setLobTotalPercentages((prev) => {
				const updatedTotalPercentages = [...prev]
				updatedTotalPercentages[lobIndex] = totalPercentage
				return updatedTotalPercentages
			})
		}

		const validateRevenuePercentage = (value, index) => {
			const newValue = parseFloat(value);
			if (isNaN(newValue) || newValue < 0) {
				return 'Value must be a positive number';
			}
			const allValues = getValues();
			const totalPercentage = allValues.lobDetails[lobIndex].segments.reduce((total, segment, currentIndex) => {
				if (currentIndex === index) {
					return total + newValue;
				}
				return total + parseFloat(segment.revenuePercentage || 0);
			}, 0);

			if (totalPercentage > 100) {
				return `Total cannot exceed 100%. Current total: ${totalPercentage.toFixed(2)}%`;
			}
			return true;
		};

		const handleRevenuePercentageChange = (event, index) => {
			let { value } = event.target;
			let newValue = parseFloat(value);

			// Get all segment values
			const allValues = getValues();
			const segments = allValues.lobDetails[lobIndex].segments;

			// Calculate current total percentage excluding the current segment
			const currentTotalWithoutCurrentSegment = segments.reduce((total, segment, currentIndex) => {
				if (currentIndex !== index) {
					return total + parseFloat(segment.revenuePercentage || 0);
				}
				return total;
			}, 0);

			// Maximum value the current field can take without exceeding 100%
			const maxAllowedValue = 100 - currentTotalWithoutCurrentSegment;

			// Clamp the value if it exceeds the max allowed value
			if (newValue > maxAllowedValue) {
				newValue = maxAllowedValue;
				enqueueSnackbar(`Total percentage cannot exceed 100%. The maximum allowed value is ${maxAllowedValue.toFixed(2)}%.`, { variant: 'error' });
			}

			// Update the input value with the clamped value
			setValue(`lobDetails[${lobIndex}].segments[${index}].revenuePercentage`, newValue.toString(), { shouldDirty: true });

			// Recalculate total percentage after change
			calculateTotalPercentage();
		};

		const handleSegmentNameChange = (event, index) => {
			const { value } = event.target
			const regex = /^\s|\s$/

			if (regex.test(value)) {
				enqueueSnackbar(`Segment name cannot start or end with a space.`, { variant: 'warning' })
			} else {
				setValue(`lobDetails[${lobIndex}].segments[${index}].segmentName`, value, { shouldDirty: true })
			}
		}

		const handleRemove = (lobIndex, segmentIndex) => {
			const allLobDetails = getValues(`lobDetails`)
			let currentSegments = [...allLobDetails[lobIndex].segments]
			currentSegments.splice(segmentIndex, 1)
			remove(segmentIndex)
			setValue(`lobDetails[${lobIndex}].segments`, currentSegments, { shouldDirty: true })
			calculateTotalPercentage()
		}

		useEffect(() => {
			if (allFormData?.lobDetails && allFormData.lobDetails[lobIndex]?.segments) {
				allFormData.lobDetails[lobIndex].segments.forEach((segment, index) => {
					if (fields.length <= index) {
						append(segment)
					} else {
						setValue(`lobDetails[${lobIndex}].segments[${index}].segmentName`, segment.segmentName, { shouldDirty: true })
						setValue(`lobDetails[${lobIndex}].segments[${index}].revenuePercentage`, segment.revenuePercentage, {
							shouldDirty: true
						})
					}
				})
				calculateTotalPercentage()
			}
		}, [allFormData, lobIndex, setValue, fields.length, append])

		useEffect(() => {
			console.log("selectedFile", file)
		}, [file])

		return (
			<Grid>
				<Grid container display={'flex'} flexDirection={'row'} alignItems='flex-end' mb={10} mt={6} xs={12}>
					<Grid item xs={7.5}>
						<Text ascent='newColor' className='question-title' display={'flex'} gap={0.5}>
							Enter revenue segments and percentage revenue
							<Box>
								<Tooltip title={'Input revenue segments and their percentage of total revenue here.'} placement='right-end'>
									<div
										style={{
											width: '15px',
											height: '15px',
											marginTop: 4
										}}
									>
										<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13 13' fill='none' width='100%' height='100%'>
											<path
												d='M6.48633 5.5C6.76247 5.5 6.98633 5.72386 6.98633 6V9C6.98633 9.27614 6.76247 9.5 6.48633 9.5C6.21019 9.5 5.98633 9.27614 5.98633 9V6C5.98633 5.72386 6.21019 5.5 6.48633 5.5ZM6.48634 4.74907C6.90002 4.74907 7.23537 4.41371 7.23537 4.00003C7.23537 3.58635 6.90002 3.251 6.48634 3.251C6.07266 3.251 5.7373 3.58635 5.7373 4.00003C5.7373 4.41371 6.07266 4.74907 6.48634 4.74907ZM0.486328 6.5C0.486328 3.18629 3.17262 0.5 6.48633 0.5C9.80004 0.5 12.4863 3.18629 12.4863 6.5C12.4863 9.81371 9.80004 12.5 6.48633 12.5C3.17262 12.5 0.486328 9.81371 0.486328 6.5ZM6.48633 1.5C3.7249 1.5 1.48633 3.73858 1.48633 6.5C1.48633 9.26142 3.7249 11.5 6.48633 11.5C9.24775 11.5 11.4863 9.26142 11.4863 6.5C11.4863 3.73858 9.24775 1.5 6.48633 1.5Z'
												fill='#242424'
											/>
										</svg>
									</div>
								</Tooltip>
							</Box>
						</Text>
					</Grid>
					<Grid xs={2.5} mb={0} display={'flex'} flexDirection={'column'} ml={-2}>
						<Grid display={'flex'} gap={0.5}>
							<Text fontWeight='700' fontSize='12px' fontFamily='Poppins' ascent='buttonBackgroundColor'>
								{lobTotalPercentages[lobIndex] ? lobTotalPercentages[lobIndex] : 0}%
							</Text>
							<Text fontWeight='500' fontSize='12px' fontFamily='Poppins'>
								Of Total Revenue
							</Text>
						</Grid>
						<Grid xs={10.5}>
							<LinearProgress
								variant='determinate'
								thickness={'10px'}
								sx={{
									'&.MuiLinearProgress-root': {
										backgroundColor: '#E8EAED',
										height: '8px',
										borderRadius: '2px',
										'& .MuiLinearProgress-bar': {
											backgroundColor: '#3B24B2'
										}
									}
								}}
								value={lobTotalPercentages[lobIndex] ? lobTotalPercentages[lobIndex] : 0}
							/>
						</Grid>
					</Grid>
				</Grid>
				{fields.map((segment, segmentIndex) => (
					<React.Fragment key={segment.id}>
						<Grid container spacing={4} key={segment.id} mb={-1} xs={12}>
							<Grid item xs={7.2} mb={2} mt={-6}>
								<Controller
									name={`lobDetails[${lobIndex}].segments[${segmentIndex}].segmentName`}
									control={control}
									rules={{ required: 'Segment Name is required' }}
									render={({ field: { onChange } }) => (
										<>
											<Text mb={0.5} className='textBox-title' ascent='newColor'>
												Segment Name
											</Text>
											<TextBox
												outlined={true}
												label='Segment Name'
												variant='outlined'
												fullWidth
												onChange={(event) => {
													onChange(event), handleSegmentNameChange(event, segmentIndex)
												}}
												defaultValue={segment.segmentName}
											/>
											<CustomErrorMessage
												name={`lobDetails[${lobIndex}].segments[${segmentIndex}].segmentName`}
												errors={errors}
												mt={-5}
											/>
										</>
									)}
								/>
							</Grid>
							<Grid item xs={3} mt={-6}>
								<Controller
									name={`lobDetails[${lobIndex}].segments[${segmentIndex}].revenuePercentage`}
									control={control}
									rules={{
										required: 'Revenue Percentage is required',
										validate: (value) => validateRevenuePercentage(value, segmentIndex)
									}}
									render={({ field }) => (
										<>
											<Text mb={0.5} className='textBox-title' ascent='newColor'>
												Revenue Percentage
											</Text>
											<TextBox
												outlined={true}
												defaultValue={segment.revenuePercentage}
												type='number'
												label='Revenue Percentage'
												variant='outlined'
												fullWidth
												endAdornment={<Text>%</Text>}
												onChange={(event) => {
													field.onChange(event)
													handleRevenuePercentageChange(event, segmentIndex)
												}}
											/>
											<CustomErrorMessage
												name={`lobDetails[${lobIndex}].segments[${segmentIndex}].revenuePercentage`}
												errors={errors}
												mt={-5}
												mb={6}
											/>
										</>
									)}
								/>
							</Grid>
							<Grid item xs={2} ml={-3} mt={-2}>
								<IconButton onClick={() => handleRemove(lobIndex, segmentIndex)}>
									<svg width='20' height='20' viewBox='0 0 16 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
										<path
											d='M0.75 4.11111H2.30556M2.30556 4.11111H14.75M2.30556 4.11111V15C2.30556 15.4126 2.46944 15.8082 2.76117 16.0999C3.05289 16.3917 3.44855 16.5556 3.86111 16.5556H11.6389C12.0514 16.5556 12.4471 16.3917 12.7388 16.0999C13.0306 15.8082 13.1944 15.4126 13.1944 15V4.11111H2.30556ZM4.63889 4.11111V2.55556C4.63889 2.143 4.80278 1.74733 5.0945 1.45561C5.38622 1.16389 5.78189 1 6.19444 1H9.30556C9.71811 1 10.1138 1.16389 10.4055 1.45561C10.6972 1.74733 10.8611 2.143 10.8611 2.55556V4.11111M6.19444 8V12.6667M9.30556 8V12.6667'
											stroke='#BDBDBD'
											strokeWidth='1.5'
											strokeLinecap='round'
											strokeLinejoin='round'
										/>
									</svg>
								</IconButton>
							</Grid>
						</Grid>
					</React.Fragment>
				))}
				<Grid xs={3}>
					<Button
						variant={'outlined'}
						startIcon={<AddCircleOutlineIcon />}
						onClick={() => append({ segmentName: '', revenuePercentage: '' })}
						fontFamily='Poppins'
						fontSize='12px'
						fontWeight='600'
					>
						Add Segment
					</Button>
				</Grid>
			</Grid>
		)
	}

	const handleInputChange = (e) => {
		const inputValue = e.target.value

		if (inputValue.length === 0) {
			enqueueSnackbar('Lob or company name cannot be empty!', { variant: 'error' })
			return
		}
		// Regex to check for leading or trailing spaces
		const regex = /^\s+|\s+$/

		// Use regex.test() to check for invalid input
		if (regex.test(inputValue)) {
			enqueueSnackbar('Please remove leading or trailing spaces.', { variant: 'error' })
			return
		}

		if (lobPreset === 'No' && listofLOB.length >= 1) {
			return
		}
		if (timeoutId) {
			clearTimeout(timeoutId)
		}
		const id = setTimeout(() => {
			setListofLOB((prevList) => [...prevList, { id: nextId, label: inputValue }])
			setNextID((prev) => prev + 1)
			setValue('line-of-business', '')
		}, 500)
		setTimeoutId(id)
	}

	const handleStockTickerChange = (e, index) => {
		if (e.length === 0) {
			enqueueSnackbar('Ticker name cannot be empty!', { variant: 'error' });
			return;
		}

		// Define regex to detect leading or trailing spaces
		const regex = /^\s+|\s+$/;

		// Check if the input matches the regex for leading or trailing spaces
		if (regex.test(e)) {
			enqueueSnackbar('Please remove leading or trailing spaces.', { variant: 'error' });
			return;
		}

		const stockAPI = fetchTickerDetails(e);

		stockAPI
			.api()
			.then((result) => {
				// Update tickerDetails in the state
				setTickerDetails((prevTickerDetails) => {
					const updatedTickerDetails = Array.isArray(prevTickerDetails) ? [...prevTickerDetails] : [];
					// Ensure the array is big enough to hold the new index
					while (updatedTickerDetails.length <= index) {
						updatedTickerDetails.push(''); // Use null or any default object for uninitialized indices
					}
					updatedTickerDetails[index] = {
						stockTicker: result?.data?.stock_ticker,
						companyName: result.data.aame,
						longBusinessSummary: result.data.longBusinessSummary,
						totalRevenue: result.data.totalRevenue,
						currency: result.data.currency,
						employees: result?.data?.employees,
						marketCap: result.data.marketCap,
						country: result?.data?.country
					};
					return updatedTickerDetails;
				});

				// Update employeeStrength in the state
				setEmployeeStrength((prevEmployeeStrength) => {
					const updatedEmployeeStrength = Array.isArray(prevEmployeeStrength) ? [...prevEmployeeStrength] : [];
					// Ensure the array is big enough
					while (updatedEmployeeStrength.length <= index) {
						updatedEmployeeStrength.push(''); // Assume default value or null
					}
					updatedEmployeeStrength[index] = result?.data?.employees || '';
					setValue(`lobDetails[${index}].annualRevenue`, result.data.totalRevenue);
					return updatedEmployeeStrength;
				});

				console.log("EmployeeStrength", employeeStrength)

				// Update tickerLoaded state
				setTickerFlag((prevTickerLoaded) => {
					const updatedTickerLoaded = Array.isArray(prevTickerLoaded) ? [...prevTickerLoaded] : [];
					// Ensure the array is big enough
					while (updatedTickerLoaded.length <= index) {
						updatedTickerLoaded.push(false); // Default to false for uninitialized indices
					}
					updatedTickerLoaded[index] = true;
					return updatedTickerLoaded;
				});
				// console.log("Updated Ticker Details", updatedTickerDetails);
			})
			.catch((err) => {
				console.log('Error in handleStockTickerChange', err);
			});
	};


	// const handleDomainSubmit = (formData) => {
	// 	// console.log('handleDomainSubmit', formData, fileNames)  // Log formData and fileNames

	// 	if (props.activeStep === 3) {
	// 		let data = { ...formData }

	// 		// Check if fileNames is an array or object and handle accordingly
	// 		if (data.lobDetails && Array.isArray(data.lobDetails)) {
	// 			data.lobDetails = data.lobDetails.map((lobDetail, index) => {
	// 				// Log to check index and corresponding file name
	// 				// console.log('Mapping lobDetail', index, fileNames[index]);

	// 				return {
	// 					...lobDetail,
	// 					Country_of_Operation:
	// 						lobDetail.Country_of_Operation && Array.isArray(lobDetail.Country_of_Operation)
	// 							? lobDetail.Country_of_Operation.map((country) => country.value)
	// 							: [],
	// 					image_url: Array.isArray(fileNames) ? fileNames[index] || null : fileNames[index.toString()] || null  // Handle both array and object cases
	// 				}
	// 			});
	// 		}

	// 		console.log("handleDomainSubmit final", data, prevData)

	// 		delete data['lob-publicly-flag']
	// 		delete data['undefined']
	// 		delete data['line-of-business']

	// 		// const submitAnswersAPI = postOrganisationAnswer()
	// 		submitAnswersAPI
	// 			.api({ data: { answers: data } })
	// 			.then(() => {
	// 				enqueueSnackbar('Assessment Submitted Successfully!', { variant: 'success', autoHideDuration: 3000 })
	// 				props.modalClose()
	// 				setTimeout(() => {
	// 					window.location.reload()
	// 				}, 1000)
	// 			})
	// 			.catch(() => {
	// 				enqueueSnackbar('Error while submitting assessment!', { variant: 'error' })
	// 			})
	// 	}
	// }

	const handleDomainSubmit = (formData) => {
		let data = { ...formData };

		if (props.activeStep === 3) {
			// Extract lobDetails from prevData, ensuring it is treated as an object
			const safePrevLobDetails = Array.isArray(prevData.lobDetails) ? prevData.lobDetails : [];

			// Handle LOB details including assigning ids based on previous data
			if (data.lobDetails && Array.isArray(data.lobDetails)) {
				data.lobDetails = data.lobDetails.map((lobDetail, index) => {
					// Find corresponding previous lobDetail using a unique identifier, e.g., lobName
					const prevLobDetail = safePrevLobDetails.find(p => p.lobName === lobDetail.lobName) || {};

					// Map LOB segments and assign segment ids
					const mappedSegments = lobDetail.segments.map((segment, segIndex) => {
						// Find corresponding previous segment using a unique identifier, e.g., segmentName
						const prevSegment = (prevLobDetail.segments || []).find(s => s.segmentName === segment.segmentName) || {};

						return {
							...segment,
							segmentId: prevSegment.segmentId || null  // Reuse existing segmentId or assign null if not found
						};
					});

					const industryArray = Array.isArray(lobDetail.industry) ? lobDetail.industry : [lobDetail.industry];

					return {
						...lobDetail,
						tickerName: lobDetail.publicly_listed === 'Yes' ? lobDetail.tickerName : null,
						lobId: prevLobDetail.lobId || null,  // Reuse existing lobId or assign null if not found
						segments: mappedSegments,
						industry: industryArray,
						Country_of_Operation: lobDetail.Country_of_Operation && Array.isArray(lobDetail.Country_of_Operation)
							? lobDetail.Country_of_Operation.map(country => country.value)
							: [],
						image_url: Array.isArray(fileNames) ? fileNames[index] || null : fileNames[index.toString()] || null
					};
				});
			}

			console.log("handleDomainSubmit final", data, prevData);

			const submitAnswersAPI = postOrganisationAnswer()
			submitAnswersAPI
				.api({ data: { answers: data } })
				.then((response) => {
					if (response) {
						if (!response.error) {
							enqueueSnackbar('Assessment Submitted Successfully!', { variant: 'success', autoHideDuration: 3000 });
							props.modalClose();
							setTimeout(() => {
								window.location.reload();
							}, 1000);
						} else {
							enqueueSnackbar(`Error while submitting assessment! - ${response.data.message} `, { variant: 'error' });
						}
					}
				})
				.catch(() => {
					enqueueSnackbar('Error while submitting assessment!', { variant: 'error' });
				});
		}
	}






	const fieldTypes = {
		RADIO: 'radio',
		SWITCH: 'switch',
		TEXT: 'text',
		MULTISELECT: 'multiselect'
	}

	// Watch for changes in the radio selection
	const watchSelectedOption = watch('line_of_business_flag', selectedOption)

	React.useEffect(() => {
		setValue(
			'lobDetails',
			listofLOB.map((lob) => ({
				lobName: lob.label,
				segments: [{ segmentName: '', revenuePercentage: '' }]
			}))
		)
	}, [listofLOB, setValue])

	React.useEffect(() => {
		const optionsAPI = getIndustryAndCountryCode()
		// const optionsAPI = getIndustryAndCountryCode()

		optionsAPI
			.api()
			.then((result) => {
				setOptionsList(result?.data)
			})
			.catch((err) => {
				console.log('getIndustryAndCountry', err)
			})
	}, [])

	useEffect(() => {
		if (prevData?.line_of_business_flag && prevData?.line_of_business_flag !== selectedOption) {
			setSelectedOption(prevData?.line_of_business_flag)
			setLOBPresent(prevData?.line_of_business_flag)
			setValue('line_of_business_flag', prevData?.line_of_business_flag)
			setValue(`name_of_company`, prevData?.name_of_company)
			prevData.lobDetails.forEach((detail, index) => {
				setPubliclyListed((prevPubliclyListed) => ({
					...prevPubliclyListed,
					[index]: detail?.publicly_listed
				}))
				setValue(`lobDetails[${index}].publicly_listed`, detail.publicly_listed)
				setListofLOB((prevList) => [...prevList, { id: prevList.length + index + 1, label: detail.lobName }])
			})
		}
	}, [prevData])

	const dependencies = useMemo(() => {
		if (watchSelectedOption !== selectedOption) {
			return [prevData, lobFields]
		} else {
			return [prevData]
		}
	}, [watchSelectedOption, selectedOption])

	useEffect(() => {
		prevData?.lobDetails?.forEach((detail, index) => {
			setValue(`lobDetails[${index}].annualRevenue`, detail.annualRevenue ? detail.annualRevenue : '')
			detail.segments.forEach((segment, segIndex) => {
				setValue(`lobDetails[${index}].segments[${segIndex}].segmentName`, segment.segmentName)
				setValue(`lobDetails[${index}].segments[${segIndex}].revenuePercentage`, segment.revenuePercentage)
			})
		})
	}, dependencies)

	React.useEffect(() => {
		// const getAnswersAPI = getQuestAnswers()
		const getAnswersAPI = getOrganisationAnswer()

		getAnswersAPI
			.api()
			.then((result) => {
				setPrevData(result?.data?.answers)
			})
			.catch((err) => {
				console.log('getAnswersAPI', err)
			})
	}, [])

	React.useEffect(() => {
		console.log('prevData', prevData)
	}, [prevData])

	return (
		<>
			<Grid container sx={{ position: 'relative' }} alignContent={'center'} justifyContent={'center'} className='main' mb={-15}>
				<Grid item xs={9}>
					<Grid xs={12} display={'flex'} justifyContent={'center'}>
						{props.steps.map((label, index) => (
							<React.Fragment key={index}>
								{props.activeStep === index && (
									<Grid xs={12}>
										<Grid
											container
											component={'form'}
											onSubmit={handleSubmit(handleDomainSubmit)}
											spacing={3}
											overflowY={'scroll'}
										>
											{props.question.map((item) => (
												<>
													{props.activeStep === item.step_id && (
														<>
															<Grid item xs={12} sm={6} md={12}>
																<Controller
																	name={`${item.fieldName}`}
																	defaultValue=''
																	control={control}
																	render={({ field: { name, value, onChange } }) => {
																		return (
																			<>
																				{props.activeStep === 0 && (
																					<Grid>
																						{item.fieldType === fieldTypes.RADIO &&
																							item.fieldName === 'line_of_business_flag' && (
																								<>
																									<WrapperCard
																										paperProps={{
																											border: '1px solid #CBCFD259'
																										}}
																										hideShadow
																									>
																										<Grid
																											display={'flex'}
																											flexDirection={'row'}
																											gap={3}
																											justifyContent={'space-around'}
																											alignItems={'center'}
																										>
																											<Text
																												ascent='newColor'
																												className='question-title'
																												display={'flex'}
																												gap={1}
																											>
																												{item.question}
																												<Box>
																													<Tooltip
																														title={
																															'A specific division within an organization that delivers products or services to a defined market segment or customer base.'
																														}
																														placement='right-end'
																													>
																														<div
																															style={{
																																width: '15px',
																																height: '15px',
																																marginTop:
																																	'3px'
																															}}
																														>
																															<svg
																																xmlns='http://www.w3.org/2000/svg'
																																viewBox='0 0 13 13'
																																fill='none'
																																width='100%'
																																height='100%'
																															>
																																<path
																																	d='M6.48633 5.5C6.76247 5.5 6.98633 5.72386 6.98633 6V9C6.98633 9.27614 6.76247 9.5 6.48633 9.5C6.21019 9.5 5.98633 9.27614 5.98633 9V6C5.98633 5.72386 6.21019 5.5 6.48633 5.5ZM6.48634 4.74907C6.90002 4.74907 7.23537 4.41371 7.23537 4.00003C7.23537 3.58635 6.90002 3.251 6.48634 3.251C6.07266 3.251 5.7373 3.58635 5.7373 4.00003C5.7373 4.41371 6.07266 4.74907 6.48634 4.74907ZM0.486328 6.5C0.486328 3.18629 3.17262 0.5 6.48633 0.5C9.80004 0.5 12.4863 3.18629 12.4863 6.5C12.4863 9.81371 9.80004 12.5 6.48633 12.5C3.17262 12.5 0.486328 9.81371 0.486328 6.5ZM6.48633 1.5C3.7249 1.5 1.48633 3.73858 1.48633 6.5C1.48633 9.26142 3.7249 11.5 6.48633 11.5C9.24775 11.5 11.4863 9.26142 11.4863 6.5C11.4863 3.73858 9.24775 1.5 6.48633 1.5Z'
																																	fill='#242424'
																																/>
																															</svg>
																														</div>
																													</Tooltip>
																												</Box>
																											</Text>
																											<RadioGroup
																												name={name}
																												value={selectedOption}
																												onChange={handleRadioChange}
																												sx={{ marginTop: -1.5 }}
																											>
																												<Grid
																													display={'flex'}
																													mt={0.5}
																												>
																													{item.option.map(
																														(op) => (
																															<FormControlLabel
																																key={op}
																																value={op}
																																control={
																																	<Radio />
																																}
																																label={op}
																																sx={{
																																	'& .MuiFormControlLabel-label':
																																	{
																																		fontSize:
																																			'12px',
																																		fontfamily:
																																			'Poppins',
																																		fontWeight: 500,
																																		marginTop: 0.5
																																	}
																																}}
																															/>
																														)
																													)}
																												</Grid>
																											</RadioGroup>
																										</Grid>
																									</WrapperCard>
																								</>
																							)}
																						{item.fieldType === fieldTypes.TEXT && (
																							<>
																								{lobPreset === 'Yes' &&
																									item.fieldName ===
																									'line-of-business' && (
																										<Grid mt={-2}>
																											<WrapperCard
																												paperProps={{
																													border: '1px solid #CBCFD259'
																												}}
																												hideShadow
																											>
																												<Grid
																													ml={'8rem'}
																													position={'relative'}
																												>
																													<Grid mb={2}>
																														<Text
																															ascent='newColor'
																															className='question-title'
																															display={'flex'}
																															gap={1}
																														>
																															{item.question}
																															<Box>
																																<Tooltip
																																	title={
																																		'This field allows you to input the name of a specific division or segment within your organization.'
																																	}
																																	placement='right-end'
																																>
																																	<div
																																		style={{
																																			width: '15px',
																																			height: '15px',
																																			marginTop:
																																				'2px'
																																		}}
																																	>
																																		<svg
																																			xmlns='http://www.w3.org/2000/svg'
																																			viewBox='0 0 13 13'
																																			fill='none'
																																			width='100%'
																																			height='100%'
																																		>
																																			<path
																																				d='M6.48633 5.5C6.76247 5.5 6.98633 5.72386 6.98633 6V9C6.98633 9.27614 6.76247 9.5 6.48633 9.5C6.21019 9.5 5.98633 9.27614 5.98633 9V6C5.98633 5.72386 6.21019 5.5 6.48633 5.5ZM6.48634 4.74907C6.90002 4.74907 7.23537 4.41371 7.23537 4.00003C7.23537 3.58635 6.90002 3.251 6.48634 3.251C6.07266 3.251 5.7373 3.58635 5.7373 4.00003C5.7373 4.41371 6.07266 4.74907 6.48634 4.74907ZM0.486328 6.5C0.486328 3.18629 3.17262 0.5 6.48633 0.5C9.80004 0.5 12.4863 3.18629 12.4863 6.5C12.4863 9.81371 9.80004 12.5 6.48633 12.5C3.17262 12.5 0.486328 9.81371 0.486328 6.5ZM6.48633 1.5C3.7249 1.5 1.48633 3.73858 1.48633 6.5C1.48633 9.26142 3.7249 11.5 6.48633 11.5C9.24775 11.5 11.4863 9.26142 11.4863 6.5C11.4863 3.73858 9.24775 1.5 6.48633 1.5Z'
																																				fill='#242424'
																																			/>
																																		</svg>
																																	</div>
																																</Tooltip>
																															</Box>
																														</Text>
																													</Grid>
																													<Grid xs={6}>
																														<Text
																															mb={1}
																															ascent='newColor'
																															className='textBox-title'
																														>
																															Type name of the
																															lob
																														</Text>
																														<TextBox
																															name={'lobName'}
																															outlined={true}
																															label={''}
																															onKeyDown={(
																																e
																															) => {
																																e.code ===
																																	'Enter'
																																	? handleInputChange(
																																		e
																																	)
																																	: ''
																															}}
																															onChange={
																																onChange
																															}
																															endAdornment={
																																<Grid
																																	display={
																																		'flex'
																																	}
																																	spacing={
																																		4
																																	}
																																>
																																	<Text
																																		ascent='washDark'
																																		className='textBox-title'
																																	>
																																		{
																																			'Press Enter'
																																		}
																																	</Text>
																																</Grid>
																															}
																															crqModalFlag={
																																true
																															}
																															value={value}
																															showLabel
																														/>
																														<CustomErrorMessage
																															name={name}
																															errors={errors}
																														/>
																													</Grid>
																													{listofLOB?.filter(
																														Boolean
																													).length > 0 && (
																															<React.Fragment>
																																<Grid
																																	mt={2}
																																	ml={1}
																																>
																																	<Grid
																																		display={
																																			'flex'
																																		}
																																		flexDirection={
																																			'column'
																																		}
																																		gap={1}
																																		alignContent={
																																			'flex-start'
																																		}
																																		alignItems={
																																			'start'
																																		}
																																		width={
																																			'100%'
																																		}
																																		height={
																																			'100%'
																																		}
																																		sx={{
																																			flexWrap:
																																				'wrap',
																																			overflowX:
																																				'scroll'
																																		}}
																																	>
																																		{listofLOB
																																			?.filter(
																																				Boolean
																																			)
																																			?.map(
																																				(
																																					lob,
																																					index
																																				) => (
																																					<>
																																						<Chip
																																							key={
																																								lob.id
																																							}
																																							label={
																																								index +
																																								1 +
																																								'. ' +
																																								lob.label
																																							}
																																							sx={{
																																								borderRadius:
																																									'5px',
																																								padding:
																																									'5px',
																																								color: '#40566D',
																																								backgroundColor:
																																									'#F5F7FA',
																																								height: '30px',
																																								margin: '0',
																																								'& .MuiChip-label':
																																								{
																																									fontSize:
																																										'12px',
																																									fontfamily:
																																										'Poppins',
																																									fontWeight: 500
																																								}
																																							}}
																																							deleteIcon={
																																								<svg
																																									width='10'
																																									height='10'
																																									viewBox='0 0 8 8'
																																									fill='none'
																																									xmlns='http://www.w3.org/2000/svg'
																																								>
																																									<path
																																										d='M7.35355 1.35355C7.54882 1.15829 7.54882 0.841709 7.35355 0.646447C7.15829 0.451184 6.84171 0.451184 6.64645 0.646447L4 3.29289L1.35355 0.646447C1.15829 0.451184 0.841709 0.451184 0.646447 0.646447C0.451184 0.841709 0.451184 1.15829 0.646447 1.35355L3.29289 4L0.646447 6.64645C0.451184 6.84171 0.451184 7.15829 0.646447 7.35355C0.841709 7.54882 1.15829 7.54882 1.35355 7.35355L4 4.70711L6.64645 7.35355C6.84171 7.54882 7.15829 7.54882 7.35355 7.35355C7.54882 7.15829 7.54882 6.84171 7.35355 6.64645L4.70711 4L7.35355 1.35355Z'
																																										fill='#768EA7'
																																									/>
																																								</svg>
																																							}
																																							onDelete={() => {
																																								setListofLOB(
																																									(
																																										prevList
																																									) =>
																																										prevList.filter(
																																											(
																																												_,
																																												i
																																											) =>
																																												i !==
																																												index
																																										)
																																								)
																																							}}
																																						/>
																																					</>
																																				)
																																			)}
																																	</Grid>
																																</Grid>
																															</React.Fragment>
																														)}
																												</Grid>
																											</WrapperCard>
																										</Grid>
																									)}
																								{lobPreset === 'No' &&
																									item.fieldName ===
																									'name_of_company' && (
																										<Grid mt={-4.8}>
																											<WrapperCard>
																												<Grid mb={1} ml={'7rem'}>
																													<Text
																														ascent='newColor'
																														className='question-title'
																														display={'flex'}
																														gap={1}
																													>
																														{item.question}
																														<Box>
																															<Tooltip
																																title={
																																	'This field allows you to input the name of the company.'
																																}
																																placement='right-end'
																															>
																																<div
																																	style={{
																																		width: '15px',
																																		height: '15px',
																																		marginTop:
																																			'2px'
																																	}}
																																>
																																	<svg
																																		xmlns='http://www.w3.org/2000/svg'
																																		viewBox='0 0 13 13'
																																		fill='none'
																																		width='100%'
																																		height='100%'
																																	>
																																		<path
																																			d='M6.48633 5.5C6.76247 5.5 6.98633 5.72386 6.98633 6V9C6.98633 9.27614 6.76247 9.5 6.48633 9.5C6.21019 9.5 5.98633 9.27614 5.98633 9V6C5.98633 5.72386 6.21019 5.5 6.48633 5.5ZM6.48634 4.74907C6.90002 4.74907 7.23537 4.41371 7.23537 4.00003C7.23537 3.58635 6.90002 3.251 6.48634 3.251C6.07266 3.251 5.7373 3.58635 5.7373 4.00003C5.7373 4.41371 6.07266 4.74907 6.48634 4.74907ZM0.486328 6.5C0.486328 3.18629 3.17262 0.5 6.48633 0.5C9.80004 0.5 12.4863 3.18629 12.4863 6.5C12.4863 9.81371 9.80004 12.5 6.48633 12.5C3.17262 12.5 0.486328 9.81371 0.486328 6.5ZM6.48633 1.5C3.7249 1.5 1.48633 3.73858 1.48633 6.5C1.48633 9.26142 3.7249 11.5 6.48633 11.5C9.24775 11.5 11.4863 9.26142 11.4863 6.5C11.4863 3.73858 9.24775 1.5 6.48633 1.5Z'
																																			fill='#242424'
																																		/>
																																	</svg>
																																</div>
																															</Tooltip>
																														</Box>
																													</Text>
																												</Grid>
																												<Grid ml={'7rem'} mt={2}>
																													<Text
																														mb={1}
																														ascent='newColor'
																														className='textBox-title'
																													>
																														Company Name
																													</Text>
																												</Grid>
																												<Grid xs={5.2} ml={'7rem'}>
																													<TextBox
																														name={name}
																														outlined={true}
																														label={''}
																														defaultValue={
																															prevData?.name_of_company
																														}
																														// onKeyDown={(e) => {
																														// 	e.code ===
																														// 		'Enter'
																														// 		? handleInputChange(
																														// 			e
																														// 		)
																														// 		: ''
																														// }}
																														onChange={(e) => {
																															handleInputChange(
																																e
																															)
																															onChange(e)
																														}
																														}
																														// endAdornment={
																														// 	<Grid
																														// 		display={
																														// 			'flex'
																														// 		}
																														// 		spacing={4}
																														// 	>
																														// 		<Text
																														// 			className='textBox-title'
																														// 			ascent='washDark'
																														// 		>
																														// 			{
																														// 				'Press Enter'
																														// 			}
																														// 		</Text>
																														// 	</Grid>
																														// }
																														crqModalFlag={true}
																														value={value}
																														showLabel
																													/>
																													<CustomErrorMessage
																														name={name}
																														errors={errors}
																													/>
																												</Grid>
																											</WrapperCard>
																										</Grid>
																									)}
																							</>
																						)}
																					</Grid>
																				)}
																				{props.activeStep === 1 && (
																					<Grid alignContent={'center'} justifyContent={'center'}>
																						{lobFields.filter(Boolean)?.map((lob, lobindex) => (
																							<Grid
																								key={item + lobindex + '_'}
																								sx={{ marginBottom: '2rem' }}
																								backgroundColor='#FBFAFC'
																							>
																								<WrapperCard
																									title={
																										lobindex + 1 + '. ' + lob.lobName
																									}
																									padding={2}
																									mb={'1rem'}
																									titleTextProps={{
																										sx: {
																											width: '52rem',
																											fontSize: '1.125rem',
																											color: '#333333',
																											borderBottom:
																												'1px #F5F5F5 solid',
																											marginLeft: '0.5rem',
																											fontFamily: 'Poppins',
																											fontWeight: 600,
																											textTransform: '',
																											marginBottom: '16px'
																										}
																									}}
																									paperProps={{
																										border: '1px solid #CBCFD259'
																									}}
																									hideShadow
																									RightComponent={
																										<IconButton
																											onClick={() =>
																												removeLob(lob.id)
																											}
																											sx={{
																												top: '-10px',
																												right: '8px'
																											}}
																										>
																											<svg
																												width='25'
																												height='25'
																												viewBox='0 0 16 18'
																												fill='none'
																												xmlns='http://www.w3.org/2000/svg'
																											>
																												<path
																													d='M0.75 4.11111H2.30556M2.30556 4.11111H14.75M2.30556 4.11111V15C2.30556 15.4126 2.46944 15.8082 2.76117 16.0999C3.05289 16.3917 3.44855 16.5556 3.86111 16.5556H11.6389C12.0514 16.5556 12.4471 16.3917 12.7388 16.0999C13.0306 15.8082 13.1944 15.4126 13.1944 15V4.11111H2.30556ZM4.63889 4.11111V2.55556C4.63889 2.143 4.80278 1.74733 5.0945 1.45561C5.38622 1.16389 5.78189 1 6.19444 1H9.30556C9.71811 1 10.1138 1.16389 10.4055 1.45561C10.6972 1.74733 10.8611 2.143 10.8611 2.55556V4.11111M6.19444 8V12.6667M9.30556 8V12.6667'
																													stroke='#BDBDBD'
																													strokeWidth='1.5'
																													strokeLinecap='round'
																													strokeLinejoin='round'
																												/>
																											</svg>
																										</IconButton>
																									}
																								>
																									<Grid
																										container
																										component={'form'}
																										mt={0}
																									>
																										<Grid item xs={12}>
																											<Controller
																												name={`lobDetails[${lobindex}].publicly_listed`}
																												control={control}
																												defaultValue={
																													prevData?.lobDetails?.[
																														lobindex
																													]?.publicly_listed ?? ''
																												}
																												render={({
																													field: {
																														onChange,
																														value,
																														name
																													}
																												}) => (
																													<>
																														<Grid>
																															{item.fieldType ===
																																fieldTypes.RADIO &&
																																item.fieldName ===
																																'lob-publicly-flag' && (
																																	<>
																																		<WrapperCard
																																			hideShadow
																																		>
																																			<Grid
																																				display={
																																					'flex'
																																				}
																																				flexDirection={
																																					'row'
																																				}
																																				gap={
																																					lobPreset ===
																																						'Yes'
																																						? 14
																																						: 23
																																				}
																																				justifyContent={
																																					'start'
																																				}
																																				xs={
																																					10
																																				}
																																				mt={
																																					'-3rem'
																																				}
																																				ml={
																																					2
																																				}
																																			>
																																				<Text
																																					ascent='newColor'
																																					className='question-title'
																																					display={
																																						'flex'
																																					}
																																					gap={
																																						1
																																					}
																																				>
																																					{lobPreset ===
																																						'Yes'
																																						? item.question
																																						: 'Is this Company Publicly Listed?'}
																																					<Box>
																																						<Tooltip
																																							title={
																																								'This question determines whether the specified Line of Business is publicly traded on the stock market'
																																							}
																																							placement='right-end'
																																						>
																																							<div
																																								style={{
																																									width: '15px',
																																									height: '15px',
																																									marginTop:
																																										'2px'
																																								}}
																																							>
																																								<svg
																																									xmlns='http://www.w3.org/2000/svg'
																																									viewBox='0 0 13 13'
																																									fill='none'
																																									width='100%'
																																									height='100%'
																																								>
																																									<path
																																										d='M6.48633 5.5C6.76247 5.5 6.98633 5.72386 6.98633 6V9C6.98633 9.27614 6.76247 9.5 6.48633 9.5C6.21019 9.5 5.98633 9.27614 5.98633 9V6C5.98633 5.72386 6.21019 5.5 6.48633 5.5ZM6.48634 4.74907C6.90002 4.74907 7.23537 4.41371 7.23537 4.00003C7.23537 3.58635 6.90002 3.251 6.48634 3.251C6.07266 3.251 5.7373 3.58635 5.7373 4.00003C5.7373 4.41371 6.07266 4.74907 6.48634 4.74907ZM0.486328 6.5C0.486328 3.18629 3.17262 0.5 6.48633 0.5C9.80004 0.5 12.4863 3.18629 12.4863 6.5C12.4863 9.81371 9.80004 12.5 6.48633 12.5C3.17262 12.5 0.486328 9.81371 0.486328 6.5ZM6.48633 1.5C3.7249 1.5 1.48633 3.73858 1.48633 6.5C1.48633 9.26142 3.7249 11.5 6.48633 11.5C9.24775 11.5 11.4863 9.26142 11.4863 6.5C11.4863 3.73858 9.24775 1.5 6.48633 1.5Z'
																																										fill='#242424'
																																									/>
																																								</svg>
																																							</div>
																																						</Tooltip>
																																					</Box>
																																				</Text>
																																				<RadioGroup
																																					name={
																																						name
																																					}
																																					value={
																																						value
																																					}
																																					onChange={(
																																						e
																																					) => {
																																						onChange(
																																							e
																																						)
																																						const newValue =
																																							e
																																								.target
																																								.value ||
																																							prevData[
																																								lobindex
																																							]
																																								.publicly_listed ||
																																							''

																																						setValue(
																																							`lobDetails[${lobindex}].publicly_listed`,
																																							newValue
																																						)
																																						setPubliclyListed(
																																							(
																																								prevPubliclyListed
																																							) => ({
																																								...prevPubliclyListed,
																																								[lobindex]:
																																									newValue
																																							})
																																						)
																																					}}
																																				>
																																					<Grid
																																						display={
																																							'flex'
																																						}
																																						mt={
																																							-1
																																						}
																																					>
																																						{item.option.map(
																																							(
																																								opt
																																							) => (
																																								<FormControlLabel
																																									key={
																																										opt
																																									}
																																									value={
																																										opt
																																									}
																																									control={
																																										<Radio />
																																									}
																																									label={
																																										opt
																																									}
																																									sx={{
																																										'& .MuiFormControlLabel-label':
																																										{
																																											fontSize:
																																												'12px',
																																											fontfamily:
																																												'Poppins',
																																											fontWeight: 400
																																										}
																																									}}
																																								/>
																																							)
																																						)}
																																					</Grid>
																																				</RadioGroup>
																																			</Grid>
																																			<Grid
																																				container
																																				display={
																																					'flex'
																																				}
																																				flexDirection={
																																					'row'
																																				}
																																				xs={
																																					12
																																				}
																																				gap={
																																					1
																																				}
																																				ml={
																																					2
																																				}
																																			>
																																				<Grid
																																					item
																																					xs={
																																						12
																																					}
																																				>
																																					{publiclyListed &&
																																						publiclyListed[
																																						lobindex
																																						] ===
																																						'Yes' ? (
																																						<Grid
																																							item
																																							xs={
																																								12
																																							}
																																							mt={
																																								2
																																							}
																																							mb={
																																								-8
																																							}
																																						>
																																							<Controller
																																								name={`lobDetails[${lobindex}].tickerName`}
																																								control={
																																									control
																																								}
																																								defaultValue={
																																									prevData
																																										?.lobDetails?.[
																																										lobindex
																																									]
																																										?.tickerName ||
																																									null
																																								}
																																								rules={{
																																									required:
																																										'Ticker name is required!',
																																									minLength:
																																									{
																																										value: 1, // Maximum allowed characters
																																										message:
																																											'Ticker name cannot be blank or empty!'
																																									}
																																								}}
																																								render={({
																																									field: {
																																										name,
																																										onChange,
																																										value
																																									}
																																								}) => (
																																									<Grid
																																										xs={
																																											12
																																										}
																																										display={
																																											'flex'
																																										}
																																										flexDirection={
																																											'column'
																																										}
																																									>
																																										{tickerFlag[
																																											lobindex
																																										] ===
																																											true ? (
																																											<Grid
																																												xs={
																																													9
																																												}
																																												mt={
																																													-1
																																												}
																																												mb={
																																													6
																																												}
																																											>
																																												<Grid
																																													item
																																													mb={
																																														1
																																													}
																																												>
																																													<Text
																																														ascent='newColor'
																																														className='textBox-title'
																																														display={
																																															'flex'
																																														}
																																														gap={
																																															0.5
																																														}
																																													>
																																														Ticker
																																														Details
																																														<Box>
																																															<Tooltip
																																																title={
																																																	'Provides unique identifier for a company stock, representing changes in its value on the financial market.'
																																																}
																																																placement='right-end'
																																															>
																																																<div
																																																	style={{
																																																		width: '15px',
																																																		height: '15px'
																																																	}}
																																																>
																																																	<svg
																																																		xmlns='http://www.w3.org/2000/svg'
																																																		viewBox='0 0 13 13'
																																																		fill='none'
																																																		width='100%'
																																																		height='100%'
																																																	>
																																																		<path
																																																			d='M6.48633 5.5C6.76247 5.5 6.98633 5.72386 6.98633 6V9C6.98633 9.27614 6.76247 9.5 6.48633 9.5C6.21019 9.5 5.98633 9.27614 5.98633 9V6C5.98633 5.72386 6.21019 5.5 6.48633 5.5ZM6.48634 4.74907C6.90002 4.74907 7.23537 4.41371 7.23537 4.00003C7.23537 3.58635 6.90002 3.251 6.48634 3.251C6.07266 3.251 5.7373 3.58635 5.7373 4.00003C5.7373 4.41371 6.07266 4.74907 6.48634 4.74907ZM0.486328 6.5C0.486328 3.18629 3.17262 0.5 6.48633 0.5C9.80004 0.5 12.4863 3.18629 12.4863 6.5C12.4863 9.81371 9.80004 12.5 6.48633 12.5C3.17262 12.5 0.486328 9.81371 0.486328 6.5ZM6.48633 1.5C3.7249 1.5 1.48633 3.73858 1.48633 6.5C1.48633 9.26142 3.7249 11.5 6.48633 11.5C9.24775 11.5 11.4863 9.26142 11.4863 6.5C11.4863 3.73858 9.24775 1.5 6.48633 1.5Z'
																																																			fill='#242424'
																																																		/>
																																																	</svg>
																																																</div>
																																															</Tooltip>
																																														</Box>
																																													</Text>
																																												</Grid>
																																												<Box
																																													backgroundColor='#F5F7FA'
																																													p={
																																														0.5
																																													}
																																													borderRadius={
																																														2
																																													}
																																													border='1px solid #E0E0E0'
																																												>
																																													<Grid
																																														ml={
																																															'97%'
																																														}
																																													>
																																														<Box
																																															sx={{
																																																cursor: 'pointer'
																																															}}
																																															onClick={() => {
																																																setTickerFlag(
																																																	(
																																																		prevTickerFlag
																																																	) =>
																																																		prevTickerFlag.map(
																																																			(
																																																				flag,
																																																				index
																																																			) =>
																																																				index ===
																																																					lobindex
																																																					? false
																																																					: flag
																																																		)
																																																)
																																															}}
																																														>
																																															<svg
																																																width='16'
																																																height='16'
																																																viewBox='0 0 16 16'
																																																fill='none'
																																																xmlns='http://www.w3.org/2000/svg'
																																															>
																																																<path
																																																	d='M12.4716 4.47138C12.7319 4.21103 12.7319 3.78892 12.4716 3.52858C12.2112 3.26823 11.7891 3.26823 11.5288 3.52858L8.00016 7.05717L4.47157 3.52858C4.21122 3.26823 3.78911 3.26823 3.52876 3.52858C3.26841 3.78892 3.26841 4.21103 3.52876 4.47138L7.05735 7.99998L3.52876 11.5286C3.26841 11.7889 3.26841 12.211 3.52876 12.4714C3.78911 12.7317 4.21122 12.7317 4.47157 12.4714L8.00016 8.94279L11.5288 12.4714C11.7891 12.7317 12.2112 12.7317 12.4716 12.4714C12.7319 12.211 12.7319 11.7889 12.4716 11.5286L8.94297 7.99998L12.4716 4.47138Z'
																																																	fill='#768EA7'
																																																/>
																																															</svg>
																																														</Box>
																																													</Grid>
																																													<Grid
																																														container
																																														display={
																																															'flex'
																																														}
																																														flexDirection={
																																															'column'
																																														}
																																														gap={
																																															-5
																																														}
																																														mt={
																																															-1
																																														}
																																														ml={
																																															1
																																														}
																																													>
																																														<Grid
																																															display={
																																																'flex'
																																															}
																																															flexDirection={
																																																'row'
																																															}
																																															gap={
																																																3
																																															}
																																															xs={
																																																12
																																															}
																																														>
																																															<Grid
																																																item
																																																display={
																																																	'flex'
																																																}
																																																flexDirection={
																																																	'row'
																																																}
																																																gap={
																																																	0.5
																																																}
																																																xs={
																																																	6
																																																}
																																															>
																																																<Text
																																																	ascent='newColor'
																																																	className='textBox-title'
																																																	sx={{
																																																		display:
																																																			'flex',
																																																		flexDirection:
																																																			'row',
																																																		gap: 1
																																																	}}
																																																>
																																																	<Box
																																																		mt={
																																																			0.4
																																																		}
																																																	>
																																																		<div
																																																			style={{
																																																				width: '16px',
																																																				height: '16px'
																																																			}}
																																																		>
																																																			<svg
																																																				width='16'
																																																				height='16'
																																																				viewBox='0 0 16 16'
																																																				fill='none'
																																																				xmlns='http://www.w3.org/2000/svg'
																																																			>
																																																				<path
																																																					d='M14.6666 4.66669L9.42083 9.91244C9.15682 10.1765 9.02482 10.3085 8.8726 10.3579C8.7387 10.4014 8.59447 10.4014 8.46057 10.3579C8.30836 10.3085 8.17635 10.1765 7.91234 9.91244L6.0875 8.0876C5.82349 7.82359 5.69148 7.69158 5.53926 7.64212C5.40537 7.59862 5.26114 7.59862 5.12724 7.64212C4.97502 7.69158 4.84302 7.82359 4.579 8.0876L1.33325 11.3334M14.6666 4.66669H9.99992M14.6666 4.66669V9.33335'
																																																					stroke='#909090'
																																																					strokeLinecap='round'
																																																					strokeLinejoin='round'
																																																				/>
																																																			</svg>
																																																		</div>
																																																	</Box>
																																																	Ticker
																																																	Id
																																																	:
																																																</Text>
																																																<Text
																																																	ascent='newColor'
																																																	className='textBox-title'
																																																>
																																																	{tickerDetails
																																																		? tickerDetails[
																																																			lobindex
																																																		]
																																																			?.stockTicker
																																																		: 'Null'}
																																																</Text>
																																															</Grid>
																																															<Grid
																																																item
																																																display={
																																																	'flex'
																																																}
																																																flexDirection={
																																																	'row'
																																																}
																																																gap={
																																																	0.5
																																																}
																																																xs={
																																																	6
																																																}
																																															>
																																																<Text
																																																	ascent='newColor'
																																																	className='textBox-title'
																																																	sx={{
																																																		display:
																																																			'flex',
																																																		flexDirection:
																																																			'row',
																																																		gap: 1
																																																	}}
																																																>
																																																	<Box
																																																		mt={
																																																			0.4
																																																		}
																																																	>
																																																		<div
																																																			style={{
																																																				width: '16px',
																																																				height: '16px'
																																																			}}
																																																		>
																																																			<svg
																																																				width='16'
																																																				height='16'
																																																				viewBox='0 0 16 16'
																																																				fill='none'
																																																				xmlns='http://www.w3.org/2000/svg'
																																																			>
																																																				<g clipPath='url(#clip0_2356_17640)'>
																																																					<path
																																																						d='M5.66659 9.77776C5.66659 10.6369 6.36303 11.3333 7.22214 11.3333H8.66659C9.58706 11.3333 10.3333 10.5871 10.3333 9.66665C10.3333 8.74617 9.58706 7.99998 8.66659 7.99998H7.33325C6.41278 7.99998 5.66659 7.25379 5.66659 6.33331C5.66659 5.41284 6.41278 4.66665 7.33325 4.66665H8.7777C9.63681 4.66665 10.3333 5.36309 10.3333 6.2222M7.99992 3.66665V4.66665M7.99992 11.3333V12.3333M14.6666 7.99998C14.6666 11.6819 11.6818 14.6666 7.99992 14.6666C4.31802 14.6666 1.33325 11.6819 1.33325 7.99998C1.33325 4.31808 4.31802 1.33331 7.99992 1.33331C11.6818 1.33331 14.6666 4.31808 14.6666 7.99998Z'
																																																						stroke='#909090'
																																																						strokeLinecap='round'
																																																						strokeLinejoin='round'
																																																					/>
																																																				</g>
																																																				<defs>
																																																					<clipPath id='clip0_2356_17640'>
																																																						<rect
																																																							width='16'
																																																							height='16'
																																																							fill='white'
																																																						/>
																																																					</clipPath>
																																																				</defs>
																																																			</svg>
																																																		</div>
																																																	</Box>
																																																	Total
																																																	Revenue
																																																	:
																																																</Text>
																																																<Text
																																																	ascent='newColor'
																																																	className='textBox-title'
																																																>
																																																	{tickerDetails
																																																		?
																																																		<Text>
																																																			$ {new Intl.NumberFormat("en-US", {
																																																				style: "decimal",
																																																				maximumFractionDigits: 2,
																																																			}).format(tickerDetails[
																																																				lobindex
																																																			]
																																																				?.totalRevenue)}
																																																		</Text>
																																																		: 'Null'}
																																																</Text>
																																															</Grid>
																																														</Grid>

																																														<Grid
																																															item
																																															display={
																																																'flex'
																																															}
																																															flexDirection={
																																																'row'
																																															}
																																															gap={
																																																0.5
																																															}
																																															xs={
																																																12
																																															}
																																														>
																																															<Grid
																																																item
																																																display={
																																																	'flex'
																																																}
																																																flexDirection={
																																																	'row'
																																																}
																																																gap={
																																																	0.5
																																																}
																																																xs={
																																																	6
																																																}
																																															>
																																																<Text
																																																	ascent='newColor'
																																																	className='textBox-title'
																																																	sx={{
																																																		display:
																																																			'flex',
																																																		flexDirection:
																																																			'row',
																																																		gap: 1
																																																	}}
																																																>
																																																	<Box
																																																		mt={
																																																			0.4
																																																		}
																																																	>
																																																		<div
																																																			style={{
																																																				width: '16px',
																																																				height: '16px'
																																																			}}
																																																		>
																																																			<svg
																																																				width='16'
																																																				height='16'
																																																				viewBox='0 0 16 16'
																																																				fill='none'
																																																				xmlns='http://www.w3.org/2000/svg'
																																																			>
																																																				<path
																																																					d='M8.66659 2.33333C8.66659 3.06971 7.02496 3.66667 4.99992 3.66667C2.97487 3.66667 1.33325 3.06971 1.33325 2.33333M8.66659 2.33333C8.66659 1.59695 7.02496 1 4.99992 1C2.97487 1 1.33325 1.59695 1.33325 2.33333M8.66659 2.33333V5.30477C7.85225 5.54932 7.33325 5.91929 7.33325 6.33333M1.33325 2.33333V10.3333C1.33325 11.0697 2.97487 11.6667 4.99992 11.6667C5.88634 11.6667 6.69929 11.5523 7.33325 11.3619V6.33333M1.33325 5C1.33325 5.73638 2.97487 6.33333 4.99992 6.33333C5.88634 6.33333 6.69929 6.21895 7.33325 6.02857M1.33325 7.66667C1.33325 8.40305 2.97487 9 4.99992 9C5.88634 9 6.69929 8.88562 7.33325 8.69523M14.6666 6.33333C14.6666 7.06971 13.025 7.66667 10.9999 7.66667C8.97487 7.66667 7.33325 7.06971 7.33325 6.33333M14.6666 6.33333C14.6666 5.59695 13.025 5 10.9999 5C8.97487 5 7.33325 5.59695 7.33325 6.33333M14.6666 6.33333V11.6667C14.6666 12.403 13.025 13 10.9999 13C8.97487 13 7.33325 12.403 7.33325 11.6667V6.33333M14.6666 9C14.6666 9.73638 13.025 10.3333 10.9999 10.3333C8.97487 10.3333 7.33325 9.73638 7.33325 9'
																																																					stroke='#909090'
																																																					strokeLinecap='round'
																																																					strokeLinejoin='round'
																																																				/>
																																																			</svg>
																																																		</div>
																																																	</Box>
																																																	Market
																																																	Cap
																																																	:
																																																</Text>
																																																<Text
																																																	ascent='newColor'
																																																	className='textBox-title'
																																																>
																																																	{tickerDetails
																																																		?
																																																		<Text>
																																																			$ {new Intl.NumberFormat("en-US", {
																																																				style: "decimal",
																																																				maximumFractionDigits: 2,
																																																			}).format(tickerDetails[
																																																				lobindex
																																																			]
																																																				?.marketCap)}
																																																		</Text>
																																																		: 'Null'}
																																																</Text>
																																															</Grid>
																																															<Grid
																																																item
																																																display={
																																																	'flex'
																																																}
																																																flexDirection={
																																																	'row'
																																																}
																																																gap={
																																																	0.5
																																																}
																																																xs={
																																																	6
																																																}
																																																ml={
																																																	2
																																																}
																																															>
																																																<Text
																																																	ascent='newColor'
																																																	className='textBox-title'
																																																	sx={{
																																																		display:
																																																			'flex',
																																																		flexDirection:
																																																			'row',
																																																		gap: 1
																																																	}}
																																																>
																																																	<Box>
																																																		<div
																																																			style={{
																																																				width: '16px',
																																																				height: '16px'
																																																			}}
																																																		>
																																																			<svg
																																																				width='16'
																																																				height='16'
																																																				viewBox='0 0 16 16'
																																																				fill='none'
																																																				xmlns='http://www.w3.org/2000/svg'
																																																			>
																																																				<path
																																																					d='M11.9999 10.5579C12.9705 11.0455 13.8026 11.828 14.4101 12.8064C14.5303 13.0002 14.5905 13.0971 14.6113 13.2312C14.6535 13.5038 14.4671 13.839 14.2132 13.9469C14.0882 14 13.9477 14 13.6666 14M10.6666 7.68816C11.6544 7.19726 12.3332 6.17791 12.3332 5C12.3332 3.82209 11.6544 2.80274 10.6666 2.31184M9.33322 5C9.33322 6.65685 7.99008 8 6.33322 8C4.67637 8 3.33322 6.65685 3.33322 5C3.33322 3.34315 4.67637 2 6.33322 2C7.99008 2 9.33322 3.34315 9.33322 5ZM1.70604 12.6256C2.76892 11.0297 4.44614 10 6.33322 10C8.22031 10 9.89753 11.0297 10.9604 12.6256C11.1932 12.9752 11.3097 13.15 11.2963 13.3733C11.2858 13.5471 11.1719 13.76 11.0329 13.8651C10.8545 14 10.6091 14 10.1183 14H2.54813C2.05734 14 1.81194 14 1.63352 13.8651C1.49459 13.76 1.38062 13.5471 1.37018 13.3733C1.35678 13.15 1.4732 12.9752 1.70604 12.6256Z'
																																																					stroke='#909090'
																																																					strokeLinecap='round'
																																																					strokeLinejoin='round'
																																																				/>
																																																			</svg>
																																																		</div>
																																																	</Box>
																																																	Full
																																																	Time
																																																	Employees:
																																																</Text>
																																																<Text
																																																	ascent='newColor'
																																																	className='textBox-title'
																																																>
																																																	{tickerDetails
																																																		? tickerDetails[
																																																			lobindex
																																																		]
																																																			?.employees
																																																		: 'Null'}
																																																</Text>
																																															</Grid>
																																														</Grid>
																																													</Grid>
																																												</Box>
																																											</Grid>
																																										) : (
																																											<Grid
																																												xs={
																																													12
																																												}
																																											>
																																												<Text
																																													mb={
																																														0.5
																																													}
																																													ascent='newColor'
																																													className='textBox-title'
																																												>
																																													Enter
																																													Stock
																																													Ticker
																																												</Text>
																																												<Grid
																																													display={
																																														'flex'
																																													}
																																													flexDirection={
																																														'row'
																																													}
																																													xs={
																																														12
																																													}
																																													gap={
																																														2
																																													}
																																												>
																																													<Grid
																																														xs={
																																															5
																																														}
																																													>
																																														<TextBox
																																															id={
																																																name
																																															}
																																															value={
																																																value
																																															}
																																															defaultValue={
																																																prevData
																																																	?.lobDetails?.[
																																																	lobindex
																																																]
																																																	?.tickerName ??
																																																''
																																															}
																																															label='Enter Stock Ticker'
																																															onChange={(
																																																e
																																															) => {
																																																onChange(
																																																	e
																																																		.target
																																																		.value
																																																)
																																															}}
																																															outlined={
																																																true
																																															}
																																															endAdornment={
																																																<Grid
																																																	display={
																																																		'flex'
																																																	}
																																																	spacing={
																																																		4
																																																	}
																																																>
																																																	<Text
																																																		fontFamily={
																																																			'Poppins'
																																																		}
																																																		ascent='washDark'
																																																		fontWeight={
																																																			600
																																																		}
																																																		fontSize={
																																																			12
																																																		}
																																																	>
																																																		{
																																																			'Press Enter'
																																																		}
																																																	</Text>
																																																</Grid>
																																															}
																																															onKeyDown={(
																																																e
																																															) => {
																																																if (
																																																	e.code ===
																																																	'Enter'
																																																) {
																																																	e.preventDefault()
																																																	handleStockTickerChange(
																																																		e
																																																			.target
																																																			.value,
																																																		lobindex
																																																	)
																																																}
																																															}}
																																															rootProps={{
																																																sx: {
																																																	'& .MuiOutlinedInput-root':
																																																	{
																																																		paddingLeft:
																																																			'5px !important',
																																																		minHeight:
																																																			'45px', // Minimum height for the input
																																																		borderRadius:
																																																			'8px', // Border radius for the input
																																																		'& fieldset':
																																																		{
																																																			borderRadius:
																																																				'8px', // Border radius for the fieldset
																																																			minHeight:
																																																				'45px' // Minimum height for the fieldset
																																																		}
																																																	}
																																																}
																																															}}
																																														/>
																																														<CustomErrorMessage
																																															name={`lobDetails[${lobindex}].tickerName`}
																																															errors={
																																																errors
																																															}
																																															mt={
																																																-5
																																															}
																																															mb={
																																																2
																																															}
																																														/>
																																													</Grid>
																																													<Grid
																																														xs={
																																															2.6
																																														}
																																													>
																																														<Box
																																															backgroundColor='#F5F7FA'
																																															p={
																																																0.5
																																															}
																																															borderRadius={
																																																2
																																															}
																																															border='1px solid #E0E0E0'
																																														>
																																															<Grid
																																																container
																																																display={
																																																	'flex'
																																																}
																																																flexDirection={
																																																	'column'
																																																}
																																																gap={
																																																	0.5
																																																}
																																															>
																																																<Grid
																																																	item
																																																	ml={
																																																		1
																																																	}
																																																>
																																																	<Text
																																																		ascent='#000000'
																																																		fontSize={
																																																			10
																																																		}
																																																		fontWeight={
																																																			500
																																																		}
																																																		fontFamily={
																																																			'Poppins'
																																																		}
																																																		display={
																																																			'flex'
																																																		}
																																																		gap={
																																																			0.5
																																																		}
																																																	>
																																																		<Box>
																																																			<Tooltip
																																																				title={
																																																					''
																																																				}
																																																				placement='right-end'
																																																			>
																																																				<div
																																																					style={{
																																																						width: '12px',
																																																						height: '12px',
																																																						marginTop:
																																																							'2px'
																																																					}}
																																																				>
																																																					<svg
																																																						xmlns='http://www.w3.org/2000/svg'
																																																						viewBox='0 0 13 13'
																																																						fill='none'
																																																						width='100%'
																																																						height='100%'
																																																					>
																																																						<path
																																																							d='M6.48633 5.5C6.76247 5.5 6.98633 5.72386 6.98633 6V9C6.98633 9.27614 6.76247 9.5 6.48633 9.5C6.21019 9.5 5.98633 9.27614 5.98633 9V6C5.98633 5.72386 6.21019 5.5 6.48633 5.5ZM6.48634 4.74907C6.90002 4.74907 7.23537 4.41371 7.23537 4.00003C7.23537 3.58635 6.90002 3.251 6.48634 3.251C6.07266 3.251 5.7373 3.58635 5.7373 4.00003C5.7373 4.41371 6.07266 4.74907 6.48634 4.74907ZM0.486328 6.5C0.486328 3.18629 3.17262 0.5 6.48633 0.5C9.80004 0.5 12.4863 3.18629 12.4863 6.5C12.4863 9.81371 9.80004 12.5 6.48633 12.5C3.17262 12.5 0.486328 9.81371 0.486328 6.5ZM6.48633 1.5C3.7249 1.5 1.48633 3.73858 1.48633 6.5C1.48633 9.26142 3.7249 11.5 6.48633 11.5C9.24775 11.5 11.4863 9.26142 11.4863 6.5C11.4863 3.73858 9.24775 1.5 6.48633 1.5Z'
																																																							fill='#242424'
																																																						/>
																																																					</svg>
																																																				</div>
																																																			</Tooltip>
																																																		</Box>
																																																		{
																																																			'Dont Know your Ticker ID!'
																																																		}
																																																	</Text>
																																																</Grid>
																																																<Grid
																																																	item
																																																	ml={
																																																		3
																																																	}
																																																>
																																																	<a
																																																		href={
																																																			'https://finance.yahoo.com/trending-tickers/'
																																																		}
																																																		target='_blank'
																																																		rel='noopener noreferrer'
																																																	>
																																																		<Text
																																																			ascent='buttonBackgroundColor'
																																																			fontSize={
																																																				10
																																																			}
																																																			fontWeight={
																																																				500
																																																			}
																																																			fontFamily={
																																																				'Poppins'
																																																			}
																																																			display={
																																																				'flex'
																																																			}
																																																			gap={
																																																				0.5
																																																			}
																																																		>
																																																			Find
																																																			Your
																																																			Ticker
																																																			Id!
																																																			<Box>
																																																				<div
																																																					style={{
																																																						width: '12px',
																																																						height: '12px'
																																																					}}
																																																				>
																																																					<svg
																																																						width='12'
																																																						height='12'
																																																						viewBox='0 0 14 15'
																																																						fill='none'
																																																						xmlns='http://www.w3.org/2000/svg'
																																																					>
																																																						<path
																																																							d='M12.25 5.75001L12.25 2.25001M12.25 2.25001H8.74999M12.25 2.25001L7 7.5M5.83333 2.25H4.55C3.56991 2.25 3.07986 2.25 2.70552 2.44074C2.37623 2.60852 2.10852 2.87623 1.94074 3.20552C1.75 3.57986 1.75 4.06991 1.75 5.05V9.95C1.75 10.9301 1.75 11.4201 1.94074 11.7945C2.10852 12.1238 2.37623 12.3915 2.70552 12.5593C3.07986 12.75 3.56991 12.75 4.55 12.75H9.45C10.4301 12.75 10.9201 12.75 11.2945 12.5593C11.6238 12.3915 11.8915 12.1238 12.0593 11.7945C12.25 11.4201 12.25 10.9301 12.25 9.95V8.66667'
																																																							stroke='#3B24B2'
																																																							strokeWidth='1.3'
																																																							strokeLinecap='round'
																																																							strokeLinejoin='round'
																																																						/>
																																																					</svg>
																																																				</div>
																																																			</Box>
																																																		</Text>
																																																	</a>
																																																</Grid>
																																															</Grid>
																																														</Box>
																																													</Grid>
																																												</Grid>
																																											</Grid>
																																										)}
																																									</Grid>
																																								)}
																																							/>
																																						</Grid>
																																					) : publiclyListed &&
																																						publiclyListed[
																																						lobindex
																																						] ===
																																						'No' ? (
																																						<Grid
																																							item
																																							xs={
																																								5
																																							}
																																							mt={
																																								2
																																							}
																																							mb={
																																								-8
																																							}
																																						>
																																							<Controller
																																								name={`lobDetails[${lobindex}].annualRevenue`}
																																								control={
																																									control
																																								}
																																								defaultValue={
																																									prevData
																																										?.lobDetails?.[
																																										lobindex
																																									]
																																										?.annualRevenue ??
																																									''
																																								}
																																								rules={{
																																									required:
																																										'Annual Revenue is required!',
																																									minLength:
																																									{
																																										value: 1, // Maximum allowed characters
																																										message:
																																											'Annual Revenue cannot be blank or empty!'
																																									}
																																								}}
																																								render={({
																																									field: {
																																										name,
																																										onChange,
																																										value
																																									}
																																								}) => (
																																									<>
																																										<Text
																																											mb={
																																												0.5
																																											}
																																											ascent='newColor'
																																											className='textBox-title'
																																										>
																																											Enter
																																											Annual
																																											Revenue
																																										</Text>
																																										<TextBox
																																											id={
																																												name
																																											}
																																											value={
																																												value
																																											}
																																											label='Enter Annual Revenue'
																																											type='number'
																																											onChange={(
																																												e
																																											) => {
																																												onChange(
																																													e
																																												)
																																											}}
																																											outlined={
																																												true
																																											}
																																											endAdornment={
																																												<Grid
																																													spacing={
																																														2
																																													}
																																												>
																																													<svg
																																														width='12'
																																														height='22'
																																														viewBox='0 0 12 20'
																																														fill='none'
																																														xmlns='http://www.w3.org/2000/svg'
																																													>
																																														<path
																																															d='M1 13.3333C1 15.1742 2.49238 16.6666 4.33333 16.6666H7.66667C9.50762 16.6666 11 15.1742 11 13.3333C11 11.4923 9.50762 9.99996 7.66667 9.99996H4.33333C2.49238 9.99996 1 8.50758 1 6.66663C1 4.82568 2.49238 3.33329 4.33333 3.33329H7.66667C9.50762 3.33329 11 4.82568 11 6.66663M6 1.66663V18.3333'
																																															stroke='#BDBDBD'
																																															strokeWidth='1.5'
																																															strokeLinecap='round'
																																															strokeLinejoin='round'
																																														/>
																																													</svg>
																																												</Grid>
																																											}
																																											rootProps={{
																																												sx: {
																																													'& .MuiOutlinedInput-root':
																																													{
																																														paddingLeft:
																																															'5px !important',
																																														minHeight:
																																															'45px', // Minimum height for the input
																																														borderRadius:
																																															'8px', // Border radius for the input
																																														'& fieldset':
																																														{
																																															borderRadius:
																																																'8px', // Border radius for the fieldset
																																															minHeight:
																																																'45px' // Minimum height for the fieldset
																																														}
																																													}
																																												}
																																											}}
																																										/>
																																										<CustomErrorMessage
																																											name={
																																												name
																																											}
																																											errors={
																																												errors
																																											}
																																											mt={
																																												-5
																																											}
																																											mb={
																																												4
																																											}
																																										/>
																																									</>
																																								)}
																																							/>
																																						</Grid>
																																					) : null}
																																				</Grid>
																																			</Grid>
																																			<Grid
																																				container
																																			>
																																				<Grid
																																					item
																																					xs={
																																						12
																																					}
																																					ml={
																																						2
																																					}
																																				>
																																					<LobSegments
																																						lobIndex={
																																							lobindex
																																						}
																																						lob={
																																							lob
																																						}
																																						allFormData={
																																							allFormDataValues
																																						}
																																					/>
																																				</Grid>
																																			</Grid>
																																		</WrapperCard>
																																	</>
																																)}
																														</Grid>
																													</>
																												)}
																											/>
																										</Grid>
																									</Grid>
																								</WrapperCard>
																							</Grid>
																						))}
																					</Grid>
																				)}
																				{props.activeStep === 2 && (
																					<Grid>
																						{listofLOB.filter(Boolean)?.map((item, index) => (
																							<Grid key={item + index + '_'} mb={2}>
																								<WrapperCard
																									title={index + 1 + '. ' + item.label}
																									padding={2}
																									titleTextProps={{
																										sx: {
																											width: '53rem',
																											fontSize: '18px',
																											color: '#333333',
																											borderBottom:
																												'1px #F5F5F5 solid',
																											marginLeft: '0.5rem',
																											fontFamily: 'Poppins',
																											fontWeight: 500,
																											textTransform: '',
																											height: 'auto'
																										}
																									}}
																									paperProps={{
																										sx: {
																											backgroundColor: '#FBFAFC'
																										},
																										border: '1px solid #CBCFD259'
																									}}
																									hideShadow
																								>
																									<Grid
																										container
																										component={'form'}
																										spacing={2}
																										mt={1}

																									>
																										<Grid item xs={4} mt={-2}>
																											<Controller
																												name={`lobDetails[${index}].industry`}
																												control={control}
																												defaultValue={
																													prevData?.lobDetails?.[
																														index
																													]?.industry ?? [
																														{
																															label: '',
																															value: ''
																														}
																													]
																												}
																												rules={{
																													required:
																														'Industry is required'
																												}}
																												render={({
																													field: {
																														name,
																														onChange,
																														value
																													}
																												}) => (
																													<>
																														<Text
																															ascent='newColor'
																															className='textBox-title'
																														>
																															Industry
																														</Text>
																														<SelectBox
																															id={name}
																															value={value}
																															label=''
																															variant={
																																'outlined'
																															}
																															onChange={
																																onChange
																															}
																															rootProps={{
																																sx: {
																																	'& .MuiSelect-select':
																																	{
																																		paddingLeft:
																																			'7px !important',
																																		minHeight:
																																			'50px', // Ensure this matches the overall field height
																																		borderRadius:
																																			'8px',
																																		lineHeight:
																																			'normal', // Adjust this if the text is not aligning properly
																																		display:
																																			'flex',
																																		alignItems:
																																			'center' // This ensures vertical centering
																																	},
																																	'& .MuiOutlinedInput-root':
																																	{
																																		'& fieldset':
																																		{
																																			borderRadius:
																																				'8px',
																																			minHeight:
																																				'50px'
																																		}
																																	}
																																}
																															}}
																															options={
																																optionsList.industry
																															}
																														/>
																														<CustomErrorMessage
																															name={`lobDetails[${index}].industry`}
																															errors={errors}
																														/>
																													</>
																												)}
																											/>
																										</Grid>
																										<Grid item xs={4} mt={-2}>
																											<Controller
																												name={`lobDetails[${index}].Country_of_Operation`}
																												control={control}
																												defaultValue={
																													prevData?.lobDetails?.[
																														index
																													]?.Country_of_Operation
																														?.length > 0
																														? optionsList?.CountryOption?.filter(
																															(option) =>
																																prevData.lobDetails[
																																	index
																																].Country_of_Operation.includes(
																																	option.value
																																)
																														)
																														: []
																												}
																												render={({
																													field: {
																														name,
																														onChange,
																														value
																													}
																												}) => (
																													<>
																														<Text
																															ascent='newColor'
																															className='textBox-title'
																														>
																															Country of
																															Operation
																														</Text>
																														<AutoCompleteBox
																															id={name}
																															value={value}
																															multiple
																															textFieldProps={{
																																variant:
																																	'outlined',
																																label: ''
																															}}
																															limitTags={2}
																															sx={{
																																border: 'none !important',
																																'.MuiAutocomplete-inputRoot':
																																{
																																	marginTop:
																																		'9px',
																																	padding:
																																		'1px !important',
																																	fontSize:
																																		'12px',
																																	borderRadius:
																																		'8px',
																																	height: '45px'
																																},
																																'& .MuiOutlinedInput-root':
																																{
																																	'& fieldset':
																																	{
																																		borderRadius:
																																			'8px',
																																		minHeight:
																																			'40px'
																																	}
																																},
																																'& .MuiSelect-select':
																																{
																																	paddingLeft:
																																		'7px !important',
																																	minHeight:
																																		'60px', // Ensure this matches the overall field height
																																	borderRadius:
																																		'10px',
																																	lineHeight:
																																		'normal', // Adjust this if the text is not aligning properly
																																	display:
																																		'flex',
																																	alignItems:
																																		'center' // This ensures vertical centering
																																}
																															}}
																															onChange={(
																																_,
																																value
																															) => {
																																onChange(
																																	value
																																)
																															}}
																															options={
																																optionsList.CountryOption
																															}
																														/>
																														<CustomErrorMessage
																															name={`lobDetails[${index}].Country_of_Operation`}
																															errors={errors}
																															mt={-5}
																														/>
																													</>
																												)}
																											/>
																										</Grid>
																										<Grid item xs={4} mt={-2}>
																											<Controller
																												name={`lobDetails[${index}].employee_strength`}
																												control={control}
																												defaultValue={
																													employeeStrength[
																													index
																													] ||
																													prevData?.lobDetails?.[
																														index
																													]?.employee_strength ||
																													''
																												}
																												rules={{
																													required:
																														'Employee Strength is required'
																												}}
																												render={({
																													field: {
																														name,
																														onChange,
																														value
																													}
																												}) => (
																													<>
																														<Text
																															ascent='newColor'
																															className='textBox-title'
																															mb={1}
																														>
																															Full time
																															Employee
																															Strength
																														</Text>
																														<TextBox
																															id={name}
																															value={value}
																															label=''
																															onChange={(
																																e
																															) => {
																																onChange(e)
																															}}
																															outlined={true}
																															rootProps={{
																																sx: {
																																	'& .MuiOutlinedInput-root':
																																	{
																																		paddingLeft:
																																			'5px !important',
																																		minHeight:
																																			'45px', // Minimum height for the input
																																		borderRadius:
																																			'8px', // Border radius for the input
																																		'& fieldset':
																																		{
																																			borderRadius:
																																				'8px', // Border radius for the fieldset
																																			minHeight:
																																				'45px' // Minimum height for the fieldset
																																		}
																																	}
																																}
																															}}
																														/>
																														<CustomErrorMessage
																															name={`lobDetails[${index}].employee_strength`}
																															errors={errors}
																															mt={-5}
																														/>
																													</>
																												)}
																											/>
																										</Grid>
																										<Grid item xs={6} mt={-2}>
																											<Controller
																												defaultValue={null}
																												render={({ field: { onChange, name, value } }) => (
																													<>
																														<Box>
																															<Text
																																ascent='newColor'
																																className='textBox-title'
																															// mb={1}
																															>
																																Company Logo
																															</Text>
																															<FileInput
																																fileType={IMAGE_FILE}
																																previewUrl={logoLink}
																																errorConfig={[
																																	{
																																		code: 'max_file_size',
																																		maxFileSize: 10 * 1024 * 1024,
																																		require: true,
																																		errorCallBack: () => {
																																			setError(name, {
																																				type: 'manual',
																																				message: 'Document size must not exceed 10MB !',
																																			});
																																		},
																																	},
																																	{
																																		code: 'file_format',
																																		allowedFormats: ['ico', 'jpeg', 'jpg', 'png'],
																																		errorCallBack: () => {
																																			setError(name, {
																																				type: 'manual',
																																				message: 'Please upload a valid file format .png,.jpeg,.jpg,.ico',
																																			});
																																		},
																																	},
																																]}
																																onChange={(e) =>
																																	fileUpload(e, index, onChange, name)
																																	// handleFileChange(e, index, onChange, name)
																																	// {
																																	// 	const selectedFile = e[0];
																																	// 	if (selectedFile) {
																																	// 		setFileNames((prev) => ({
																																	// 			...prev,
																																	// 			[index]: selectedFile.path, // Save the file name based on the index
																																	// 		}));
																																	// 		setFile(selectedFile)
																																	// 		onChange(selectedFile);
																																	// 		console.log('file upload', selectedFile.path);
																																	// 		clearErrors(name);
																																	// 	} else { }
																																	// }
																																}
																																onClick={(e) => {
																																	onChange('');
																																	e.target.value = null;
																																}}
																																name={name}
																																buttonLabel={file[index] ? file[index] : 'Drag and Drop here or Browse files'}
																																accept='.png,.jpeg,.jpg,.ico'
																															/>
																															<CustomErrorMessage textAlign='justify' name={name} errors={errors} />
																														</Box>
																														{/* <Text ascent='primary' margin='0 0 1rem 0'>
																															.png, .jpeg,.jpg and .ico files are supported. File size should not exceed more than 10mb
																														</Text> */}
																														{/* <Button onClick={() => fileUpload(file)} variant='outlined' disabled={!file}>
																															Upload
																														</Button> */}
																														{fileNames[index] && (
																															<Button
																																onClick={() => handleReset(index, onChange, name)}
																																variant='outlined'
																																sx={{
																																	marginLeft: '1rem',
																																}}
																															>
																																Reset
																															</Button>
																														)}

																													</>
																												)}
																												control={control}
																												name={`lobDetails[${index}].image_url`}
																											/>
																										</Grid>
																										<Grid item xs={6} display={'flex'} mt={-2} justifyContent={'center'} alignItems={'center'}>
																											<Text ascent='primary'>
																												.png, .jpeg,.jpg and .ico files are supported. File size should not exceed more than 10mb
																											</Text>
																										</Grid>
																									</Grid>
																								</WrapperCard>
																							</Grid>
																						))}
																					</Grid>
																				)}
																				{props.activeStep === 3 && (
																					<WrapperCard
																						paperProps={{
																							border: '1px solid #CBCFD259'
																						}}
																						hideShadow
																					>
																						<Grid container xs={12} ml={2}>
																							<Grid
																								container
																								direction='column'
																								xs={12}
																								justifyContent='center' // Centers content vertically within the item
																								alignItems='center'
																							>
																								<Text
																									ascent='newColor'
																									className='overview-title'
																								>
																									Business Details
																								</Text>
																							</Grid>
																							<Grid
																								display={'flex'}
																								flexDirection={'column'}
																								mt={2}
																								gap={1}
																							>
																								<Grid item xs={12}>
																									<Text
																										className='question-title'
																										ascent='newColor'
																									>
																										{props.question[0].question}
																									</Text>
																								</Grid>
																								<Grid item xs={12} ml={0}>
																									<Text
																										className='question-answer'
																										ascent='newColor'
																									>
																										{
																											allFormDataValues[
																											'line_of_business_flag'
																											]
																										}
																									</Text>
																								</Grid>
																							</Grid>
																							<Grid container xs={12} mt={4}>
																								<Grid item xs={12}>
																									{lobPreset === 'Yes' ? (
																										<Text
																											className='question-title'
																											ascent='newColor'
																										>
																											Line Of Business Names
																										</Text>
																									) : (
																										<Text
																											className='question-title'
																											ascent='newColor'
																										>
																											Company Name
																										</Text>
																									)}
																								</Grid>
																								<Grid item xs={12} ml={2}>
																									{listofLOB?.map((lob, index) => (
																										<Text
																											key={index} // It's good to have a unique key for each child in a list
																											className='question-answer'
																											ascent='newColor'
																											mb={0.5}
																										>
																											{listofLOB.length > 1
																												? index +
																												1 +
																												'.' +
																												' ' +
																												lob.label
																												: lob.label}
																										</Text>
																									))}
																								</Grid>
																							</Grid>
																							<hr
																								style={{
																									width: '97%',
																									marginTop: '20px',
																									marginBottom: '20px',
																									marginLeft: '-5px'
																								}}
																							/>
																							<Grid
																								container
																								direction='column'
																								xs={12}
																								justifyContent='center' // Centers content vertically within the item
																								alignItems='center'
																							>
																								<Text
																									ascent='newColor'
																									className='overview-title'
																								>
																									{
																										allFormDataValues[
																											'line_of_business_flag'
																										] === 'Yes' ? 'LOB Details' : 'Organisation Details'
																									}
																								</Text>
																							</Grid>
																							<Grid
																								display={'flex'}
																								flexDirection={'column'}
																								mt={3}
																								gap={2}
																								xs={11.6}
																							>
																								{allFormDataValues?.lobDetails?.map(
																									(val, i) => (
																										// eslint-disable-next-line react/jsx-key
																										<WrapperCard
																											title={
																												i + 1 + '. ' + val.lobName
																											}
																											padding={2}
																											titleTextProps={{
																												sx: {
																													width: '50rem',
																													fontSize: '18px',
																													color: '#333333',
																													fontFamily: 'Poppins',
																													fontWeight: 500,
																													textTransform: ''
																												}
																											}}
																											paperProps={{
																												sx: {
																													backgroundColor:
																														'#FBFAFC'
																												},
																												border: '1px solid #CBCFD259'
																											}}
																											hideShadow
																											pb={1}
																											RightComponent={
																												<IconButton
																													onClick={() =>
																														toggleEditModeLob(i)
																													}
																													sx={{
																														top: '-10px',
																														right: '8px'
																													}}
																												>
																													{isEditModeLob[i] ? (
																														<svg
																															width='18'
																															height='18'
																															viewBox='0 0 18 18'
																															fill='none'
																															xmlns='http://www.w3.org/2000/svg'
																														>
																															<path
																																d='M15 4.5L6.75 12.75L3 9'
																																stroke='#969697'
																																strokeWidth='1.3'
																																strokeLinecap='round'
																																strokeLinejoin='round'
																															/>
																														</svg>
																													) : (
																														<svg
																															width='22'
																															height='25'
																															viewBox='0 0 18 19'
																															fill='none'
																															xmlns='http://www.w3.org/2000/svg'
																														>
																															<path
																																d='M8.25 3.50017H5.1C3.83988 3.50017 3.20982 3.50017 2.72852 3.74541C2.30516 3.96112 1.96095 4.30533 1.74524 4.72869C1.5 5.20999 1.5 5.84005 1.5 7.10017V13.4002C1.5 14.6603 1.5 15.2903 1.74524 15.7716C1.96095 16.195 2.30516 16.5392 2.72852 16.7549C3.20982 17.0002 3.83988 17.0002 5.1 17.0002H11.4C12.6601 17.0002 13.2902 17.0002 13.7715 16.7549C14.1948 16.5392 14.539 16.195 14.7548 15.7716C15 15.2903 15 14.6603 15 13.4002V10.2502M5.99998 12.5002H7.25589C7.62277 12.5002 7.80622 12.5002 7.97885 12.4587C8.1319 12.422 8.27822 12.3614 8.41243 12.2791C8.5638 12.1864 8.69352 12.0567 8.95294 11.7972L16.125 4.62517C16.7463 4.00385 16.7463 2.99649 16.125 2.37517C15.5037 1.75385 14.4963 1.75385 13.875 2.37517L6.70293 9.54723C6.4435 9.80665 6.31378 9.93637 6.22102 10.0877C6.13878 10.222 6.07817 10.3683 6.04143 10.5213C5.99998 10.694 5.99998 10.8774 5.99998 11.2443V12.5002Z'
																																stroke='#333333'
																																strokeOpacity='0.5'
																																strokeWidth='1.05'
																																strokeLinecap='round'
																																strokeLinejoin='round'
																															/>
																														</svg>
																													)}
																												</IconButton>
																											}
																										>
																											<Grid
																												container
																												xs={
																													isEditModeLob[i]
																														? 12
																														: 10
																												}
																												gap={1}
																												display={'flex'}
																												flexDirection={'row'}
																												ml={
																													isEditModeLob[i] ? 0 : 3
																												}
																											>
																												<Grid item xs={3.8}>
																													{isEditModeLob[i] ? (
																														<Controller
																															name={`lobDetails[${i}].industry`}
																															control={
																																control
																															}
																															defaultValue={
																																allFormDataValues
																																	.lobDetails[
																																	i
																																].industry
																															}
																															rules={{
																																required:
																																	'Industry is required'
																															}}
																															render={({
																																field: {
																																	name,
																																	onChange,
																																	value
																																}
																															}) => (
																																<Grid
																																	mt={-5}
																																>
																																	<Text
																																		ascent='newColor'
																																		className='textBox-title'
																																	>
																																		Industry
																																	</Text>
																																	<SelectBox
																																		id={
																																			name
																																		}
																																		value={
																																			value
																																		}
																																		label=''
																																		variant={
																																			'outlined'
																																		}
																																		onChange={
																																			onChange
																																		}
																																		rootProps={{
																																			sx: {
																																				'& .MuiSelect-select':
																																				{
																																					paddingLeft:
																																						'7px !important',
																																					minHeight:
																																						'30px', // Ensure this matches the overall field height
																																					borderRadius:
																																						'8px',
																																					lineHeight:
																																						'normal', // Adjust this if the text is not aligning properly
																																					display:
																																						'flex',
																																					alignItems:
																																						'center' // This ensures vertical centering
																																				},
																																				'& .MuiOutlinedInput-root':
																																				{
																																					'& fieldset':
																																					{
																																						borderRadius:
																																							'8px',
																																						minHeight:
																																							'30px'
																																					}
																																				}
																																			}
																																		}}
																																		options={
																																			optionsList.industry
																																		}
																																	/>
																																	<CustomErrorMessage
																																		name={`lobDetails[${i}].industry`}
																																		errors={
																																			errors
																																		}
																																	/>
																																</Grid>
																															)}
																														/>
																													) : (
																														<Grid mt={-5}>
																															<Text
																																ascent='newColor'
																																className='textBox-title'
																																mb={1}
																															>
																																Industry
																															</Text>
																															<Text mt={-1}>
																																{
																																	val.industry
																																}
																															</Text>
																														</Grid>
																													)}
																												</Grid>
																												<Grid item xs={3.8}>
																													{isEditModeLob[i] ? (
																														<Controller
																															name={`lobDetails[${i}].Country_of_Operation`}
																															control={
																																control
																															}
																															defaultValue={
																																allFormDataValues
																																	.lobDetails[
																																	i
																																]
																																	.Country_of_Operation
																															}
																															render={({
																																field: {
																																	name,
																																	onChange,
																																	value
																																}
																															}) => (
																																<Grid
																																	mt={-5}
																																>
																																	<Text
																																		ascent='newColor'
																																		className='textBox-title'
																																	>
																																		Country
																																		of
																																		Operation
																																	</Text>
																																	<AutoCompleteBox
																																		id={
																																			name
																																		}
																																		value={
																																			value
																																		}
																																		multiple
																																		textFieldProps={{
																																			variant:
																																				'outlined',
																																			label: ''
																																		}}
																																		limitTags={
																																			2
																																		}
																																		sx={{
																																			border: 'none !important',
																																			'.MuiAutocomplete-inputRoot':
																																			{
																																				marginTop:
																																					'9px',
																																				padding:
																																					'1px !important',
																																				fontSize:
																																					'12px',
																																				borderRadius:
																																					'8px',
																																				height: '45px'
																																			},
																																			'& .MuiOutlinedInput-root':
																																			{
																																				'& fieldset':
																																				{
																																					borderRadius:
																																						'8px',
																																					minHeight:
																																						'45px'
																																				}
																																			},
																																			'& .MuiSelect-select':
																																			{
																																				paddingLeft:
																																					'7px !important',
																																				minHeight:
																																					'60px', // Ensure this matches the overall field height
																																				borderRadius:
																																					'10px',
																																				lineHeight:
																																					'normal', // Adjust this if the text is not aligning properly
																																				display:
																																					'flex',
																																				alignItems:
																																					'center' // This ensures vertical centering
																																			}
																																		}}
																																		onChange={(
																																			_,
																																			value
																																		) => {
																																			onChange(
																																				value
																																			)
																																		}}
																																		options={
																																			optionsList.CountryOption
																																		}
																																	/>
																																	<CustomErrorMessage
																																		name={
																																			name
																																		}
																																		errors={
																																			errors
																																		}
																																	/>
																																</Grid>
																															)}
																														/>
																													) : (
																														<Grid mt={-5}>
																															<Text
																																ascent='newColor'
																																className='textBox-title'
																															>
																																Country Of
																																Operation
																															</Text>
																															<Text>
																																{val?.Country_of_Operation?.map(
																																	(
																																		country
																																	) =>
																																		country.value
																																).join(
																																	', '
																																)}
																															</Text>
																														</Grid>
																													)}
																												</Grid>
																												<Grid item xs={3.8}>
																													{isEditModeLob[i] ? (
																														<Controller
																															name={`lobDetails[${i}].employee_strength`}
																															control={
																																control
																															}
																															defaultValue={
																																allFormDataValues
																																	.lobDetails[
																																	i
																																]
																																	.employee_strength
																															}
																															rules={{
																																required:
																																	'Employee Strength is required'
																															}}
																															render={({
																																field: {
																																	name,
																																	onChange,
																																	value
																																}
																															}) => (
																																<Grid
																																	mt={-5}
																																>
																																	<Text
																																		ascent='newColor'
																																		className='textBox-title'
																																		mb={
																																			1
																																		}
																																	>
																																		Full
																																		time
																																		Employee
																																		Strength
																																	</Text>
																																	<TextBox
																																		id={
																																			name
																																		}
																																		value={
																																			value
																																		}
																																		label=''
																																		onChange={(
																																			e
																																		) => {
																																			onChange(
																																				e
																																			)
																																		}}
																																		outlined={
																																			true
																																		}
																																		rootProps={{
																																			sx: {
																																				'& .MuiOutlinedInput-root':
																																				{
																																					paddingLeft:
																																						'5px !important',
																																					minHeight:
																																						'45px', // Minimum height for the input
																																					borderRadius:
																																						'8px', // Border radius for the input
																																					'& fieldset':
																																					{
																																						borderRadius:
																																							'8px', // Border radius for the fieldset
																																						minHeight:
																																							'45px' // Minimum height for the fieldset
																																					}
																																				}
																																			}
																																		}}
																																	/>
																																	<CustomErrorMessage
																																		name={`lobDetails[${index}].employee_strength`}
																																		errors={
																																			errors
																																		}
																																	/>
																																</Grid>
																															)}
																														/>
																													) : (
																														<Grid mt={-5}>
																															<Text
																																ascent='newColor'
																																className='textBox-title'
																															>
																																Full time
																																Employee
																																Strength
																															</Text>
																															<Text
																																className='question-answer'
																																ascent='newColor'
																															>
																																{
																																	val.employee_strength
																																}
																															</Text>
																														</Grid>
																													)}
																												</Grid>
																											</Grid>
																										</WrapperCard>
																									)
																								)}
																							</Grid>
																							<hr
																								style={{
																									width: '97%',
																									marginTop: '20px',
																									marginBottom: '20px',
																									marginLeft: '-5px'
																								}}
																							/>
																							<Grid
																								container
																								direction='column'
																								xs={12}
																								justifyContent='center' // Centers content vertically within the item
																								alignItems='center'
																							>
																								<Text
																									ascent='newColor'
																									className='overview-title'
																								>
																									Revenue Details
																								</Text>
																							</Grid>
																							<Grid
																								display={'flex'}
																								flexDirection={'column'}
																								mt={3}
																								gap={2}
																								xs={11.6}
																							>
																								{allFormDataValues?.lobDetails?.map(
																									(val, i) => (
																										// eslint-disable-next-line react/jsx-key
																										<WrapperCard
																											title={
																												i + 1 + '. ' + val.lobName
																											}
																											padding={2}
																											titleTextProps={{
																												sx: {
																													width: '50rem',
																													fontSize: '18px',
																													color: '#333333',
																													fontFamily: 'Poppins',
																													fontWeight: 500,
																													textTransform: ''
																												}
																											}}
																											paperProps={{
																												sx: {
																													backgroundColor:
																														'#FBFAFC'
																												},
																												border: '1px solid #CBCFD259'
																											}}
																											hideShadow
																											pb={2}
																											RightComponent={
																												<IconButton
																													onClick={() =>
																														toggleEditModeRevenue(
																															i
																														)
																													}
																													sx={{
																														top: '-10px',
																														right: '8px'
																													}}
																												>
																													{editModeRevenue[i] ? (
																														<svg
																															width='18'
																															height='18'
																															viewBox='0 0 18 18'
																															fill='none'
																															xmlns='http://www.w3.org/2000/svg'
																														>
																															<path
																																d='M15 4.5L6.75 12.75L3 9'
																																stroke='#969697'
																																strokeWidth='1.3'
																																strokeLinecap='round'
																																strokeLinejoin='round'
																															/>
																														</svg>
																													) : (
																														<svg
																															width='22'
																															height='25'
																															viewBox='0 0 18 19'
																															fill='none'
																															xmlns='http://www.w3.org/2000/svg'
																														>
																															<path
																																d='M8.25 3.50017H5.1C3.83988 3.50017 3.20982 3.50017 2.72852 3.74541C2.30516 3.96112 1.96095 4.30533 1.74524 4.72869C1.5 5.20999 1.5 5.84005 1.5 7.10017V13.4002C1.5 14.6603 1.5 15.2903 1.74524 15.7716C1.96095 16.195 2.30516 16.5392 2.72852 16.7549C3.20982 17.0002 3.83988 17.0002 5.1 17.0002H11.4C12.6601 17.0002 13.2902 17.0002 13.7715 16.7549C14.1948 16.5392 14.539 16.195 14.7548 15.7716C15 15.2903 15 14.6603 15 13.4002V10.2502M5.99998 12.5002H7.25589C7.62277 12.5002 7.80622 12.5002 7.97885 12.4587C8.1319 12.422 8.27822 12.3614 8.41243 12.2791C8.5638 12.1864 8.69352 12.0567 8.95294 11.7972L16.125 4.62517C16.7463 4.00385 16.7463 2.99649 16.125 2.37517C15.5037 1.75385 14.4963 1.75385 13.875 2.37517L6.70293 9.54723C6.4435 9.80665 6.31378 9.93637 6.22102 10.0877C6.13878 10.222 6.07817 10.3683 6.04143 10.5213C5.99998 10.694 5.99998 10.8774 5.99998 11.2443V12.5002Z'
																																stroke='#333333'
																																strokeOpacity='0.5'
																																strokeWidth='1.05'
																																strokeLinecap='round'
																																strokeLinejoin='round'
																															/>
																														</svg>
																													)}
																												</IconButton>
																											}
																										>
																											<Grid
																												container
																												xs={12}
																												display={'flex'}
																												flexDirection={'column'}
																												mt={-4}
																											>
																												<Grid
																													item
																													xs={12}
																													mb={2}
																													display={'flex'}
																													flexDirection={'column'}
																												>
																													<Text
																														className='question-title'
																														ascent='newColor'
																													>
																														Is this line of
																														business publicly
																														listed?
																													</Text>
																													<Text
																														className='question-answer'
																														ascent={'newColor'}
																													>
																														{
																															val.publicly_listed
																														}
																													</Text>
																												</Grid>
																												<Grid
																													item
																													xs={12}
																													mb={1}
																													display={'flex'}
																													flexDirection={'column'}
																												>
																													{val.publicly_listed ===
																														'Yes' ? (
																														<>
																															<Text
																																className='textBox-title'
																																ascent='newColor'
																															>
																																Enter stock
																																ticker
																															</Text>
																															<Text
																																className='question-answer'
																																ascent={
																																	'newColor'
																																}
																															>
																																{
																																	val.tickerName
																																}
																															</Text>
																														</>
																													) : (
																														<>
																															<Text
																																className='textBox-title'
																																ascent='newColor'
																															>
																																Annual
																																Revenue
																															</Text>
																															<Text
																																className='question-answer'
																																ascent={
																																	'newColor'
																																}
																															>
																																{
																																	val.annualRevenue
																																}
																															</Text>
																														</>
																													)}
																												</Grid>
																												<Grid
																													item
																													mb={1}
																													xs={12}
																													display={'flex'}
																													flexDirection={'column'}
																													mt={0}
																												>
																													{editModeRevenue[i] ? (
																														<LobSegments
																															lobIndex={i}
																															lob={val}
																															initialData={
																																prevData
																															}
																															allFormData={
																																allFormDataValues
																															}
																														/>
																													) : (
																														<Grid
																															container
																															display={'flex'}
																															flexDirection={
																																'row'
																															}
																															alignItems={
																																'flex-end'
																															}
																															gap={1}
																														>
																															<Grid
																																item
																																xs={6.4}
																																mt={3}
																																mb={-1.5}
																															>
																																<Text
																																	className='question-title'
																																	ascent='newColor'
																																>
																																	Enter
																																	revenue
																																	segments
																																	and
																																	percentage
																																	revenue
																																</Text>
																															</Grid>
																															<Grid
																																xs={2.5}
																																display={
																																	'flex'
																																}
																																flexDirection={
																																	'column'
																																}
																															>
																																<Grid
																																	xs={12}
																																>
																																	<Grid>
																																		<Grid
																																			display={
																																				'flex'
																																			}
																																			gap={
																																				0.5
																																			}
																																		>
																																			<Text
																																				fontWeight='700'
																																				fontSize='12px'
																																				fontFamily='Poppins'
																																				ascent='buttonBackgroundColor'
																																			>
																																				{val?.segments?.reduce(
																																					(
																																						total,
																																						seg
																																					) =>
																																						total +
																																						parseFloat(
																																							seg.revenuePercentage ||
																																							0
																																						),
																																					0
																																				)}

																																				%
																																			</Text>
																																			<Text
																																				fontWeight='500'
																																				fontSize='12px'
																																				fontFamily='Poppins'
																																			>
																																				Of
																																				Total
																																				Revenue
																																			</Text>
																																		</Grid>
																																		<Box
																																			sx={{
																																				height: '70%',
																																				width: '95%',
																																				display:
																																					'flex',
																																				flexDirection:
																																					'column'
																																			}}
																																			position={
																																				'relative'
																																			}
																																		>
																																			<LinearProgress
																																				variant='determinate'
																																				thickness={
																																					'10px'
																																				}
																																				sx={{
																																					'&.MuiLinearProgress-root':
																																					{
																																						backgroundColor:
																																							'#E8EAED',
																																						height: '8px',
																																						borderRadius:
																																							'2px',
																																						'& .MuiLinearProgress-bar':
																																						{
																																							backgroundColor:
																																								'#3B24B2'
																																						}
																																					}
																																				}}
																																				value={val?.segments?.reduce(
																																					(
																																						total,
																																						seg
																																					) =>
																																						total +
																																						parseFloat(
																																							seg.revenuePercentage ||
																																							0
																																						),
																																					0
																																				)}
																																			/>
																																		</Box>
																																	</Grid>
																																</Grid>
																															</Grid>
																															{val?.segments?.map(
																																(value) => (
																																	// eslint-disable-next-line react/jsx-key
																																	<Grid
																																		item
																																		xs={
																																			12
																																		}
																																		display={
																																			'flex'
																																		}
																																		flexDirection={
																																			'row'
																																		}
																																		mb={
																																			1
																																		}
																																		mt={
																																			2
																																		}
																																	>
																																		<Grid
																																			item
																																			xs={
																																				7
																																			}
																																			display={
																																				'flex'
																																			}
																																			flexDirection={
																																				'column'
																																			}
																																		>
																																			<Text
																																				ascent='newColor'
																																				className='textBox-title'
																																			>
																																				Segment
																																				Name
																																			</Text>
																																			<Text
																																				ascent='newColor'
																																				className='question-answer'
																																			>
																																				{
																																					value.segmentName
																																				}
																																			</Text>
																																		</Grid>
																																		<Grid
																																			item
																																			xs={
																																				5.8
																																			}
																																			display={
																																				'flex'
																																			}
																																			flexDirection={
																																				'column'
																																			}
																																		>
																																			<Text
																																				ascent='newColor'
																																				className='textBox-title'
																																			>
																																				Revenue
																																				Percentage
																																			</Text>
																																			<Text
																																				className='question-answer'
																																				ascent='newColor'
																																			>
																																				{value.revenuePercentage +
																																					'%'}
																																			</Text>
																																		</Grid>
																																	</Grid>
																																)
																															)}
																														</Grid>
																													)}
																												</Grid>
																											</Grid>
																										</WrapperCard>
																									)
																								)}
																							</Grid>
																						</Grid>
																					</WrapperCard>
																				)}
																			</>
																		)
																	}}
																/>
															</Grid>
														</>
													)}
												</>
											))}
										</Grid>
									</Grid>
								)}
							</React.Fragment>
						))}
					</Grid>
				</Grid>
			</Grid>
			<Grid
				xs={12}
				display={'flex'}
				justifyContent={'flex-end'}
				alignItems={'center'}
				sx={{ position: 'sticky', bottom: 0, zIndex: 1100 }}
				mt={props.activeStep === 0 || props.activeStep === 1 || props.activeStep === 2 || props.activeStep === 3 ? '30vh' : 0}
				backgroundColor={'white'}
				height='68px'
			>
				<Grid mr={10}>
					{props.activeStep > 0 && (
						<Button
							variant='contained'
							onClick={props.backHandler}
							ascent={'backButton'}
							disableAscent={'baseDisabled'}
							disableColorAscent={'washLight'}
							sx={{
								mt: 1,
								mr: 1,
								padding: 1,
								border: '1px solid #E0E0E0',
								Opacity: '30%'
							}}
						>
							<Text fontWeight={500} ascent={'dark'} fontSize={12} fontFamily='Poppins'>
								{'Back'}
							</Text>
						</Button>
					)}
					<Button
						variant='contained'
						ascent={'buttonBackgroundColor'}
						onClick={
							props.activeStep === props.steps.length - 1
								? handleSubmit(handleDomainSubmit)
								: () => props.nextHandler(allFormDataValues, listofLOB)
						}
						sx={{
							mt: 1,
							mr: 1,
							pr: 2,
							padding: props.activeStep != props.steps.length - 1 ? 0.9 : '.50rem 1rem'
						}}
					>
						{props.activeStep != props.steps.length - 1 && <Box ml={2}></Box>}
						<Text fontWeight={500} ascent='white' fontSize={12} fontFamily='Poppins'>
							{props.activeStep === props.steps.length - 1 ? `Submit` : `Save and Continue`}
						</Text>
						{props.activeStep != props.steps.length - 1 && (
							<>
								<Box mr={0.5}></Box>
								<svg width='16' height='18' viewBox='0 0 12 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
									<path
										d='M7.80474 3.52876C7.54439 3.26841 7.12228 3.26841 6.86193 3.52876C6.60158 3.78911 6.60158 4.21122 6.86193 4.47157L9.72386 7.3335H0.666667C0.298477 7.3335 0 7.63197 0 8.00016C0 8.36835 0.298477 8.66683 0.666667 8.66683H9.72386L6.86193 11.5288C6.60158 11.7891 6.60158 12.2112 6.86193 12.4716C7.12228 12.7319 7.54439 12.7319 7.80474 12.4716L11.8047 8.47157C12.0651 8.21122 12.0651 7.78911 11.8047 7.52876L7.80474 3.52876Z'
										fill='white'
									/>
								</svg>
							</>
						)}
					</Button>
				</Grid>
			</Grid>
		</>
	)
}
